import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
// import { FormManage } from '../../companies/shared/custom-validators/form-handling.validator';
import Swal from 'sweetalert2';
// import { CompaniesService } from '../../shared/services/companies.service';
import { FormManage } from '../../shared/custom-validators/form-handling.validator';
import { CompaniesService } from '../../companies/shared/services/companies.service';
import { FacadeService } from '../../shared/services/facade.service';

@Component({
  selector: 'app-update',
  templateUrl: './update.component.html',
  styleUrls: ['./update.component.scss']
})
export class UpdateComponent extends FormManage implements OnInit {
  companyId;
  companyForm: FormGroup;
  company_details;
  isLoading: boolean = false;
  membershipList:any[]=[];
  constructor(private router: Router, private facade: FacadeService,
    private activatedRoute: ActivatedRoute
    ,private companyService: CompaniesService) {
    super();
    this.companyId = this.activatedRoute.snapshot.params.id;

  }

  ngOnInit(): void {
    this.initForm();

    this.companyService.getMemberships().subscribe({
      next: (res: any) => {
        console.log(res);
        this.membershipList = res.data;
      },
      error: (err: any) => {
        console.log(err);
      }
    })
    this.companyService.getRefCompany(this.companyId).subscribe({
      next: (res) => {
        this.company_details=res.data;
        console.log(this.company_details);

      }
    })

  }
  initForm() {
    this.companyForm = new FormGroup({
      id:new FormControl(''),
      name: new FormControl(``, [Validators.required, Validators.minLength(2)]),
      email: new FormControl('', [Validators.pattern(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]),
      ref_membership_id: new FormControl("", Validators.required),
      ref_wallet_offer: new FormControl("", Validators.required),
      code: new FormControl("", Validators.required)

    })

    this.setForm(this.companyForm);
  }
  get f() {
    return this.companyForm.controls;
  }
  confirm(companyForm) {
    if (this.companyForm.invalid) {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'Please fill the required data.',
        showConfirmButton: false,
        timer: 1500
      })
      this.companyForm.markAllAsTouched();
    } else {
      console.log(companyForm.value);
      companyForm.controls['id'].setValue(this.company_details.id);

      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger mx-2'
        },
        buttonsStyling: false
      })
      swalWithBootstrapButtons.fire({
        title: 'Are you Sure?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Update',
        cancelButtonText: 'Cancel',
      }).then((result) => {
        if (result.isConfirmed) {
          this.onSubmit(companyForm.value);
        }
      })
    }
  }
  // formOpertaion() {
  //   if (this.f.is_percentage.value) {
  //     this.f.is_percentage.setValue(1);
  //   } else {
  //     this.f.is_percentage.setValue(0);
  //   }

  //   if (this.f.is_labour_percentage.value) {
  //     this.f.is_labour_percentage.setValue(1);
  //   } else {
  //     this.f.is_labour_percentage.setValue(0);
  //   }
  // }
  // passwordMatch() {
  //   if (this.f.password_confirmation.touched) {
  //     if (this.f.password.value === this.f.password_confirmation.value) {
  //       this.f.password_confirmation.setErrors(null)
  //     } else {
  //       this.f.password_confirmation.setErrors({ not_matched: true })
  //     }
  //   }

  // }
  onSubmit(form) {
    this.isLoading = true;
    console.log(form);
    this.companyService.updateRefCompany(form).subscribe({
      next: (res) => {
        console.log(res);
        Swal.fire({
              position: 'center',
              icon: 'success',
              title: 'Company is Updated successfully.',
              showConfirmButton: false,
              timer: 1500
            }).then(result => {
              this.router.navigate(['/refCompany'])
            })
      },
      error: (err) => {
        console.log(err);
          if (err.error.status === 400) {
            Swal.fire({
              position: 'center',
              icon: 'error',
              title: err.error.data,
              showConfirmButton: false,
              timer: 1500
            })
          } else {
            Swal.fire({
              position: 'center',
              icon: 'error',
              title: 'Something went wrong',
              showConfirmButton: false,
              timer: 1500
            })
      }
            this.isLoading = false

      }
    })
    // this.formOpertaion();
    // this.companyService.createRefCompany(companyForm.value).subscribe(res => {
    //   console.log(res);
    //   Swal.fire({
    //     position: 'center',
    //     icon: 'success',
    //     title: 'Company is Created successfully.',
    //     showConfirmButton: false,
    //     timer: 1500
    //   }).then(result => {
    //     this.router.navigate(['/refCompany'])
    //   })
    // }, err => {
    //   console.log(err);
    //   if (err.error.status === 400) {
    //     Swal.fire({
    //       position: 'center',
    //       icon: 'error',
    //       title: err.error.data,
    //       showConfirmButton: false,
    //       timer: 1500
    //     })
    //   } else {
    //     Swal.fire({
    //       position: 'center',
    //       icon: 'error',
    //       title: 'Something went wrong',
    //       showConfirmButton: false,
    //       timer: 1500
    //     })
    //   }
    //   this.isLoading = false
    // })
  }
}
