<div>
    <app-alert *ngIf="!company" type="danger text-center font-weight-bold">
        <h2>
            {{ "navigation.company" | translate }}
            {{ "navigation.details" | translate }}
            {{'errors.not_found' |translate}}
        </h2>
    </app-alert>
    <app-card [cardTitle]="('forms.update company' | translate)" [options]="false" *ngIf="company">
        <form [formGroup]="companyForm" (ngSubmit)="confirm()" *ngIf="companyForm">
            <div class="row">
                <div class="col-md-6 col-12 ">
                    <h5 class="sub-title">{{"forms.username" | translate}} <span class="text-danger"> * </span> </h5>
                    <input class="form-control form-control-sm" type="text" placeholder="Enter user name" rqeuired
                        formControlName="name" />
                    <app-field-error [displayError]="isFieldValid('name')"
                        errorMsg="Please enter a valid name with more than 2 characters">
                    </app-field-error>
                </div>
                <div class="col-md-6 col-12 pt-3 pt-md-0">
                    <div class="row">
                        <div class="col-3">
                            <h5 class="sub-title">{{"forms.discount" | translate}}</h5>
                            <h6 class="p-3 badge badge-secondary"> 
                                {{company?.value}} 
                                <span *ngIf="company?.is_percentage">%</span>
                                <span *ngIf="!company?.is_percentage">EGP</span>
                            </h6>
                        </div>
                        <div class="col-9">
                            <div class="form-group align-items-center">
                                <h5 class="sub-title">{{"forms.labour_discount" | translate}}</h5>
                                <h6 class="p-3 badge badge-secondary"> 
                                    {{company?.labour_discount}} 
                                    <span *ngIf="company?.is_labour_percentage">%</span>
                                    <span *ngIf="!company?.is_labour_percentage">EGP</span>
                                </h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row py-3">
                <div class="col-md-6 col-12">
                    <h5 class="sub-title">
                        {{"forms.password" | translate}}
                        <small class="text-secondary"> (optional)</small>
                    </h5>
                    <input class="form-control form-control-sm" type="password" autocomplete="off"
                        placeholder="Enter New password" formControlName="password" />
                    <app-field-error [displayError]="isFieldValid('password')"
                        errorMsg="Please enter a valid name with more than 2 characters">
                    </app-field-error>
                </div>
                <div class="col-md-6 col-12 pt-3 pt-md-0">
                    <h5 class="sub-title">{{"forms.Email" | translate}} <span class="text-danger"> * </span></h5>
                    <input class="form-control form-control-sm" type="text" placeholder="Enter Email" rqeuired
                        formControlName="email" />
                    <app-field-error [displayError]="isFieldValid('email')"
                        [errorMsg]="'errors.entervalidemail' | translate">
                    </app-field-error>
                </div>
            </div>
            <div class="row py-3">
                <div class="col-md-4 col-12 d-flex justify-content-between flex-wrap">
                    <div>
                        <h5 class="sub-title">{{"forms.phone" | translate}}</h5>
                        <h6>{{company?.user?.phone}}</h6>
                    </div>
                    <div>
                        <h5 class="sub-title">{{"forms.key" | translate}}</h5>
                        <h6>{{company?.key}}</h6>
                    </div>
                </div>
                <div class="col-2 d-none d-lg-block"></div>
                <div class="col-md-6 col-12 pt-3 pt-md-0">
                    <h5 class="sub-title">{{"forms.created_at" | translate}}<span class="text-danger"> </span></h5>
                    <h6>
                        <span>{{company?.created_at | date:'fullDate'}}</span>
                        <span class="mx-2 px-2 border-left border-dark">{{company?.created_at | date:'mediumTime'}}</span>
                    </h6>
                </div>
            </div>


            <div class="row mt-3">
                <div class="col-md-2 col-12 ">
                    <div form-submit-btn [btnLable]="('buttons.update' | translate)" [formInvalid]="companyForm.invalid"
                        [isLoading]="isLoading" type="submit">
                    </div>
                </div>
            </div>
        </form>
    </app-card>
</div>