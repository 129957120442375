import { Injectable } from '@angular/core';
import { EndPoints } from 'src/app/shared/endpoints/endpoints';
import { HttpClientService } from 'src/app/shared/http-confing/http/http-client.service';

@Injectable({
  providedIn: 'root',
})
export class CouponService {
  constructor(private http: HttpClientService) {}
  couponAPI = EndPoints.COUPON_ENDPOINT;

  public createCoupon(body) {
    return this.http.post(body, `${this.couponAPI}/create`);
  }

  public getAllCoupon(
    isPaginate: boolean = false,
    page?: number,
    active?: number,
    search?: string
  ) {
    if (!isPaginate) {
      page = null;
    }
    return this.http.get(`${this.couponAPI}/all`, {
      is_paginate: `${+isPaginate}`,
      page,
      active,
      search,
    });
  }

  public getCoupon(couponId: number, isLocalization: boolean = false) {
    return this.http.get(`${this.couponAPI}/get`, {
      id: couponId,
      is_localization: `${+isLocalization}`,
    });
  }

  public updateCoupon(body) {
    return this.http.post(body, `${this.couponAPI}/update`);
  }

  public deleteCoupon(couponId: number) {
    return this.http.post({ id: couponId }, `${this.couponAPI}/delete`);
  }
}
