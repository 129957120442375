import { Component, OnInit } from '@angular/core';
import { IPagination } from 'src/app/shared/models/response.model';
import { Router, ActivatedRoute } from '@angular/router';
import { CompaniesService } from '../companies/shared/services/companies.service';
import { ICompany } from '../companies/shared/models/icompany';
import Swal from 'sweetalert2';
import { FacadeService } from '../shared/services/facade.service';

@Component({
  selector: 'app-ref-company',
  templateUrl: './ref-company.component.html',
  styleUrls: ['./ref-company.component.scss']
})
export class RefCompanyComponent implements OnInit {

  companies: ICompany[] = [];
  pagination: IPagination = {
    currentPage: 1,
    totalItems: 0,
    itemsPerPage: 15,
  };
  constructor(private router: Router, private facade: FacadeService,private ActivatedRoute: ActivatedRoute, private companyService: CompaniesService) { }

  ngOnInit(): void {
    this.ActivatedRoute.queryParamMap.subscribe(qParams => {
      if (qParams.has('page')) {
        this.pagination.currentPage = parseInt(qParams.get('page'))
      }
      this.getCompaniesList()
    })
  }
  pageChanged(page) {
    this.pagination.currentPage = page;
    this.router.navigate([], { queryParams: { page } });
  }
  getCompaniesList() {
    this.companyService.getRefComanies(this.pagination.currentPage).subscribe(res => {
      this.pagination.itemsPerPage = res.data.per_page;
      this.pagination.totalItems = res.data.total;
      // this.pagination.itemsPerPage = res.data.per_page;
      // this.pagination.totalItems = res.data.total;
      this.companies = res.data.data;
      console.log(res);
    }, err => {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger mx-2'
        },
        buttonsStyling: false
      })
      swalWithBootstrapButtons.fire({
        title: 'Some thing went wrong!!',
        icon: 'error',
        showCancelButton: true,
        confirmButtonText: 'Retry',
        cancelButtonText: 'Cancel',
      }).then((result) => {
        if (result.isConfirmed) {
          this.getCompaniesList();
        }
      })
    })
  }
  confirmBan(id: number, activeValue: number) {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-success',
        cancelButton: 'btn btn-danger mx-2'
      },
      buttonsStyling: false
    })
    swalWithBootstrapButtons.fire({
      title: 'Click Ok to confirm this action!',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Ok',
      cancelButtonText: 'Cancel',
    }).then((result) => {
      if (result.isConfirmed) {
        this.update(id, activeValue);
      }
    })
  }
  update(id: number, activeValue: number) {
    let body = { active: activeValue };
    this.companyService.changeBan(id, body).subscribe(res => {
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Successfull Operation.',
        showConfirmButton: false,
        timer: 1500
      }).then(result => {
        this.getCompaniesList();
      })
    }, err => {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger mx-2'
        },
        buttonsStyling: false
      })
      swalWithBootstrapButtons.fire({
        title: 'Some thing went wrong!!',
        icon: 'error',
        showCancelButton: true,
        confirmButtonText: 'Retry',
        cancelButtonText: 'Cancel',
      }).then((result) => {
        if (result.isConfirmed) {
          this.update(id, activeValue);
        }
      })
    })
  }
}
