import { Component, OnInit } from '@angular/core';
import { HttpClientService } from '../shared/http-confing/http/http-client.service';
import { IPagination } from '../shared/models/response.model';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {
  contacts:{[key:string]:any}[]=[];

  paginationOptions: IPagination = {
    currentPage: 1,
    totalItems: 0,
    itemsPerPage: 15,
  };

  constructor(private http: HttpClientService) { }

  ngOnInit(): void {
    this.http.get('contactUs').subscribe((res:any)=>{
      this.paginationOptions.itemsPerPage=res.data.per_page;
      this.paginationOptions.totalItems=res.data.total;
      this.contacts=res.data.data;
    
    })
  }

  pageChanged($event:number){
    this.paginationOptions.currentPage=$event;
    this.http.get('contactUs',{
      page:$event
    }).subscribe((res:any)=>{
      this.paginationOptions.itemsPerPage=res.data.per_page;
      this.paginationOptions.totalItems=res.data.total;
      this.contacts=res.data.data;
        console.log(res)
    })

  }
}
