import { HttpClientService } from './../../http-confing/http/http-client.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class PaymentService {
  constructor(private http: HttpClientService) {}

  public createPayment(body) {
    return this.http.postFormData(body, `payment/create`);
  }
  public createBank(body) {
    return this.http.post(body, `bank/create-bank`);
  }

  public getAllPayment(
    isPaginate: boolean = false,
    page?: number,
    active?: number,
    search?: string
  ) {
    if (!isPaginate) {
      page = null;
    }
    return this.http.get(`payment/all`, {
      is_paginate: `${+isPaginate}`,
      page,
      active,
      search,
    });
  }
  public getAllBank(
    isPaginate: boolean = false,
    page?: number,
    active?: number,
    search?: string
  ) {
    if (!isPaginate) {
      page = null;
    }
    return this.http.get(`bank/get-all`);
  }

  public getpaymentData(paymentId: number, isLocalization: boolean = false) {
    return this.http.get(`payment/get`, {
      id: paymentId,
      is_localization: `${+isLocalization}`,
    });
  }
  public getBankData(bankId: number, isLocalization: boolean = false) {
    return this.http.get(`bank/get/${bankId}`);
  }
  public updatePayment(body) {
    return this.http.postFormData(body, `payment/update`);
  }
  public updateBank(body) {
    return this.http.post(body, `bank/update-bank`);
  }
  public updateOrder(body) {
    return this.http.post(body, `payment/reordering`);
  }
}
