import { Interceptor } from './shared/http-confing/interceptors/interceptor';
import { ToggleFullScreenDirective } from './shared/full-screen/toggle-full-screen';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { AdminComponent } from './layout/admin/admin.component';
import { AuthComponent } from './layout/auth/auth.component';
import { NavigationComponent } from './layout/admin/navigation/navigation.component';
import { NavContentComponent } from './layout/admin/navigation/nav-content/nav-content.component';
import { NavGroupComponent } from './layout/admin/navigation/nav-content/nav-group/nav-group.component';
import { NavCollapseComponent } from './layout/admin/navigation/nav-content/nav-collapse/nav-collapse.component';
import { NavItemComponent } from './layout/admin/navigation/nav-content/nav-item/nav-item.component';
import { NavBarComponent } from './layout/admin/nav-bar/nav-bar.component';
import { NavLeftComponent } from './layout/admin/nav-bar/nav-left/nav-left.component';
import { NavSearchComponent } from './layout/admin/nav-bar/nav-left/nav-search/nav-search.component';
import { NavRightComponent } from './layout/admin/nav-bar/nav-right/nav-right.component';
import { ConfigurationComponent } from './layout/admin/configuration/configuration.component';

/* Menu Items */
import { NavigationItem } from './layout/admin/navigation/navigation';
import {
  NgbButtonsModule,
  NgbDropdownModule,
  NgbNavModule,
  NgbTooltipModule,
} from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from './shared/shared.module';
import { HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { AngularFireModule } from '@angular/fire';
import { environment } from 'src/environments/environment';
import { PageNotFoundComponent } from './general/page-not-found/page-not-found.component';
import { AsyncPipe } from '@angular/common';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { RefCompanyComponent } from './ref-company/ref-company.component';
import { CompaniesModule } from './companies/companies.module';
import { CreateComponent } from './ref-company/create/create.component';
import { UpdateComponent } from './ref-company/update/update.component';
import { QrCodesComponent } from './qr-codes/qr-codes.component';
import { QrCreateComponent } from './qr-codes/create/create.component';

// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}
@NgModule({
  declarations: [
    AppComponent,
    AdminComponent,
    AuthComponent,
    NavigationComponent,
    NavContentComponent,
    NavGroupComponent,
    NavCollapseComponent,
    NavItemComponent,
    NavBarComponent,
    NavLeftComponent,
    NavSearchComponent,
    NavRightComponent,
    ConfigurationComponent,
    ToggleFullScreenDirective,
    PageNotFoundComponent,
    ContactUsComponent,
    RefCompanyComponent,
    CreateComponent,
    UpdateComponent,
    QrCodesComponent,
    QrCreateComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    SharedModule,
    CompaniesModule,
    NgbDropdownModule,
    NgbTooltipModule,
    NgbButtonsModule,
    NgbNavModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireMessagingModule,
  ],
  providers: [
    AsyncPipe,
    NavigationItem,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: Interceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
