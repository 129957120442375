<h5 class="sub-title" style="color: white;">{{"navigation.contactUs" | translate}}</h5>



<div class="col-xl-12">
    <app-card [customHeader]="true"  [options]="false">
      <div class="app-card-header">
        <h5 class="mb-3">
          {{'navigation.contactUs' | translate}}
        </h5>

      <div class="table-responsive">
        <table class="table table-striped">
          <thead>
            <tr>
                <th>{{'forms.name' | translate}}</th>

                <th>{{'forms.email' | translate}}</th>

              <th>{{"forms.phone" | translate}}</th>

              <th>{{"forms.vehicle" | translate}}</th>
              <th>{{"forms.date" | translate}}</th>
              <th>{{"forms.time" | translate}}</th>
              <th>{{"forms.appointment_reason" | translate}}</th>
              <th>{{"forms.createdAt" | translate}}</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let contact of contacts | paginate: paginationOptions ; let i = index">
                 <td>{{ contact['name'] }}</td>
                <td>{{ contact['email'] }}</td>
                <td>{{ contact['phone'] }}</td>
                <td>{{ contact['vehicle'] }}</td>
                <td>{{ contact['date'] | date:'medium' }}</td>
                <td>{{ contact['time'] }}</td>
                <td>{{ contact['appointment_reason'] }}</td>
                <td>{{ contact['created_at']  | date:'medium' }}</td>
            </tr>
          </tbody>
        </table>
        <div class="row">
            <div class="col-12 text-center">
              <pagination-controls
      
                (pageChange)="pageChanged($event)"
                previousLabel="Previous"
                nextLabel="Next"
                screenReaderPaginationLabel="Pagination"
                screenReaderPageLabel="page"
                screenReaderCurrentLabel="You're on page"
              >
              </pagination-controls>
            </div>
          </div>
      </div>

      </div>

    </app-card>
  </div>
  