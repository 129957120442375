import { Injectable } from '@angular/core';
import { HttpClientService } from '../../../../app/shared/http-confing/http/http-client.service';
import { EndPoints } from '../../endpoints/endpoints';

@Injectable({
  providedIn: 'root',
})
export class CountryService {
  constructor(private http: HttpClientService) {}
  countryAPI = EndPoints.COUNTRY_ENDPOINT;

  public createCountry(body: { name_en: string; name_ar: string }) {
    return this.http.postFormData(body, `${this.countryAPI}/create`);
  }

  public getAllCountries(
    isPaginate: boolean = false,
    page?: number,
    active?:number,
    search?: string
  ) {
    if (!isPaginate) {
      page = null;
    }
    return this.http.get(`${this.countryAPI}/all`, {
      is_paginate: `${+isPaginate}`,
      page,
      active,
      search,
    });
  }

  public getCountry(CountryId: number, isLocalization: boolean = false) {
    return this.http.get(`${this.countryAPI}/get`, {
      id: CountryId,
      is_localization: `${+isLocalization}`,
    });
  }

  public updateCountry(body) {
    return this.http.postFormData(body, `${this.countryAPI}/update`);
  }

  public deleteCountry(CountryId: number) {
    return this.http.post({ id: CountryId }, `${this.countryAPI}/delete`);
  }
}
