import { HttpClientService } from './../../../../shared/http-confing/http/http-client.service';
import { EndPoints } from './../../../../shared/endpoints/endpoints';
import { Response } from './../../../../shared/models/response.model';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Cars } from 'src/app/shared/models/cars.model';



@Injectable({
	providedIn: 'root'
})
export class ManufacturerService {
	apiURLManufacturer = EndPoints.MANUFACTURER_ENDPOINT

	constructor(private https: HttpClientService, private router: Router) { }
	// lang?: string
	getAll(isPaginate: boolean = false,
		page?: number,
		active?: number ,
		search?: string): Observable<any> {
		if (!isPaginate){
			page = null;}
		return this.https.get(`${this.apiURLManufacturer}/all`, {
			is_paginate: `${+isPaginate}`,
			page,
			active,
			search,
		});

	}
	// : Observable<Response<Cars[]>>
	getById(Id): Observable<Response<Cars>> {
		return this.https.get(`${this.apiURLManufacturer}/get`, { id: Id });
	}

	create(payLoad: Cars): Observable<Response<Cars>> {

		return this.https.postFormData(payLoad, `${this.apiURLManufacturer}/create`);
	}

	update(body): Observable<Response<Cars>> {
		return this.https.postFormData(body, `${this.apiURLManufacturer}/update`);
	}
	delete(id): Observable<Response<Cars>> {
		return this.https.post({ id: id }, `${this.apiURLManufacturer}/delete`);

	}
}
