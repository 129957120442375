import { Injectable } from '@angular/core';
import { EndPoints } from '../../../shared/endpoints/endpoints';
import { Observable } from 'rxjs';
import { Response } from '../../../shared/models/response.model';
import { HttpClientService } from '../../../shared/http-confing/http/http-client.service';

@Injectable({
  providedIn: 'root'
})
export class CompaniesService {
  private apiURL = EndPoints.COMPANY_ENDPOINT;
  constructor(private https: HttpClientService) { }

  getAll(page: number): Observable<Response<any>> {
    return this.https.get(`${this.apiURL}/all`, { is_paginate: 1, page: page })
  }
  getById(id: number): Observable<Response<any>> {
    return this.https.get(`${this.apiURL}/get`, { id: id })
  }
  getCompanyDetails(id: number,pageNumber:number) {
    return this.https.get(`${this.apiURL}/MyUsers`, { company_id: id, is_paginate: 1, page: pageNumber })
  }
  create(body): Observable<Response<any>> {
    return this.https.postFormData(body, `${this.apiURL}/create-company`)
  }
  update(body): Observable<Response<any>> {
    return this.https.postFormData(body, `${this.apiURL}/updateUser`)
  }
  changeBan(id: number, body): Observable<Response<any>> {
    return this.https.postFormData(body, `${this.apiURL}/update`, { id: id })
  }
  getOrders(id: number): Observable<Response<any>> {
    return this.https.get(`${this.apiURL}/MyOrders`, { user_id: id })
  }
  getPricesHistory(id: number,pageNumber:number){
    return this.https.get(`${this.apiURL}/getHistoryCompany`, { id: id, is_paginate: 1, page: pageNumber })
  }
  getCurrentDiscount(id: number){
    return this.https.get(`${this.apiURL}/getCurrentDiscount`, { id: id})
  }
  addNewCompanyDiscount(body): Observable<Response<any>> {
    return this.https.postFormData(body, `${this.apiURL}/addPriceToCompany`)
  }
  getRefComanies(page): Observable<Response<any>> {
    return this.https.get(`ref_company/all`, { is_paginate: 1, page: page })
  }
  createRefCompany(body): Observable<any> {
    return this.https.post(body, `ref_company/create`)
  }
  getMemberships():Observable<Response<any>> {
    return this.https.get( `membership/all`)
  }
  getRefCompany(id) :Observable<Response<any>> {
    return this.https.get(`ref_company/get?id=`+id)
  }
  updateRefCompany(body): Observable<any> {
    return this.https.post(body, `ref_company/update`)
  }
}
