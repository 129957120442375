import { Response } from './../../../shared/models/response.model';
import { HttpClientService } from './../../../shared/http-confing/http/http-client.service';
import { EndPoints } from '../../../shared/endpoints/endpoints';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
// import { CategoryModel } from '../models';
import { CategoryModel } from './../models/categories.model';

@Injectable({
  providedIn: 'root',
})
export class CategoriesService {
  apiURLModel = EndPoints.CATEGORIES_ENDPOINT;

  constructor(private https: HttpClientService, private router: Router) {}
  // lang?: string
  getAll(
    isPaginate: boolean = false,
    page?: number,
    active?: number,
    search?: string
  ) {
    if (!isPaginate) {
      page = 0;
    }

    return this.https.get(`${this.apiURLModel}/all`, {
      is_paginate: `${+isPaginate}`,
      page,
      active,
      search,
    });
  }
  getAllFilter(body) {
    return this.https.get(`${this.apiURLModel}/all`, body);
  }
  // getAllByManufacturer(manufacturerId: number) {
  //   return this.https.get(`${this.apiURLModel}/all`, {
  //     manufacturer_id: manufacturerId,
  //   });
  // }
  // : Observable<Response<Data<Cars[]>>>
  getById(Id): Observable<Response<CategoryModel>> {
    return this.https.get(`${this.apiURLModel}/get`, { id: Id });
  }

  create(payLoad): Observable<Response<CategoryModel>> {
    return this.https.postFormData(payLoad, `${this.apiURLModel}/create`);
  }

  update(payLoad): Observable<Response<CategoryModel>> {
    return this.https.postFormData(payLoad, `${this.apiURLModel}/update`);
  }
  delete(id): Observable<Response<CategoryModel>> {
    return this.https.post({ id: id }, `${this.apiURLModel}/delete`);
  }
  getSelectedServiceModels(serviceModelsIds: any) {
    return this.https.get(`${this.apiURLModel}/bulk-get`, {
      service_model_ids: serviceModelsIds,
    });
  }
  updateMultiServiceModels(payLoad) {
    return this.https.postFormData(payLoad, `service/bulk-edit-spare-parts`);
  }
}
