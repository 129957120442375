import { FacadeService } from './../../services/facade.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { Subject } from 'rxjs';
import { debounceTime ,distinctUntilChanged} from 'rxjs/operators';

@Component({
  selector: '[app-search]',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {
  keyword = '';
  emptyList: boolean = false;
  private keywordUpdated: Subject<string> = new Subject<string>();

  @Input() url = "manufacturer";
  @Input() name;
  @Output() onSearch = new EventEmitter();

  constructor(private fb: FormBuilder, private facade: FacadeService) {}

  ngOnInit(): void {
    this.keywordUpdated.pipe(
      debounceTime(700) , // Debounce time in milliseconds
      distinctUntilChanged() 
    ).subscribe(value => {
      this.save();
    });
  }

  onKeywordChange(): void {
    this.keywordUpdated.next(this.keyword);
  }

  save(): void {    
    if (this.keyword) {
      this.facade.searchService.search(this.keyword, this.url).subscribe(res => {
        if (res.status == 200) {
          this.onSearch.emit(res.data);
          if (!res.data.length) {
            this.emptyList = true;
            setTimeout(() => {
              this.emptyList = false;
              this.keyword = '';  // Reset the keyword
            }, 1000);
          }
        }
      }, err => {
        this.onSearch.emit('false');
      });
    } else {
      this.onSearch.emit('');
    }
  }
}
