import { Injectable } from '@angular/core';
import { EndPoints } from '../../endpoints/endpoints';
import { HttpClientService } from '../../http-confing/http/http-client.service';

@Injectable({
  providedIn: 'root',
})
export class TechnicianService {
  constructor(private http: HttpClientService) {}
  techicianAPI = EndPoints.TECHNICIAN_ENDPOINT;
  reviewsAPI = EndPoints.REVIEW_ENDPOINT;

  public createTechnician(body) {
    return this.http.postFormData(body, `${this.techicianAPI}/create`);
  }

  public getAllTechnicians(
    isPaginate: boolean = false,
    page?: number,
    search?: string
  ) {
    if (!isPaginate) {
      page = null;
    }
    return this.http.get(`${this.techicianAPI}/all`, {
      is_paginate: `${+isPaginate}`,
      page,
      search,
    });
  }

  public getTechnician(TechnicianId: number, isLocalization: boolean = false) {
    return this.http.get(`${this.techicianAPI}/get`, {
      id: TechnicianId,
      is_localization: `${+isLocalization}`,
    });
  }

  public updateTechnician(body: {
    id: number;
    name_en: string;
    name_ar: string;
  }) {
    return this.http.postFormData(body, `${this.techicianAPI}/update`);
  }

  public deleteTechnician(TechnicianId: number) {
    return this.http.post({ id: TechnicianId }, `${this.techicianAPI}/delete`);
  }
  getTechReviews(technician_id , isPaginate){
    return this.http.get(`${this.reviewsAPI}/all`,{technician_id:technician_id,is_paginate:isPaginate})
  }
}
