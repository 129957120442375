import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { FormManage } from '../../../shared/custom-validators/form-handling.validator';
import Swal from 'sweetalert2';
import { CompaniesService } from '../../shared/services/companies.service';
@Component({
  selector: 'app-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss']
})
export class CreateComponent extends FormManage implements OnInit {
  companyForm: FormGroup;
  isLoading: boolean = false;

  constructor(private router: Router, private companyService: CompaniesService) {
    super();
  }

  ngOnInit(): void {
    this.initForm();
  }
  initForm() {
    this.companyForm = new FormGroup({
      name: new FormControl('', [Validators.required, Validators.minLength(2)]),
      email: new FormControl('', [Validators.pattern(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]),
      phone: new FormControl('', [Validators.required, Validators.minLength(2), Validators.pattern(/^[0-9]{0,11}$/)]),
      password: new FormControl('', [Validators.required, Validators.minLength(8), Validators.maxLength(22)]),
      password_confirmation: new FormControl('', [Validators.required, Validators.minLength(8), Validators.maxLength(22)]),
      value: new FormControl(0),
      is_percentage: new FormControl(0),
      labour_discount: new FormControl(0),
      is_labour_percentage: new FormControl(0),
      active: new FormControl(1)
    })

    this.setForm(this.companyForm);
  }
  get f() {
    return this.companyForm.controls;
  }
  confirm() {
    if (this.companyForm.invalid) {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'Please fill the required data.',
        showConfirmButton: false,
        timer: 1500
      })
      this.companyForm.markAllAsTouched();
    } else {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger mx-2'
        },
        buttonsStyling: false
      })
      swalWithBootstrapButtons.fire({
        title: 'Are you Sure?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Create',
        cancelButtonText: 'Cancel',
      }).then((result) => {
        if (result.isConfirmed) {
          this.onSubmit();
        }
      })
    }
  }
  formOpertaion() {
    if (this.f.is_percentage.value) {
      this.f.is_percentage.setValue(1);
    } else {
      this.f.is_percentage.setValue(0);
    }

    if (this.f.is_labour_percentage.value) {
      this.f.is_labour_percentage.setValue(1);
    } else {
      this.f.is_labour_percentage.setValue(0);
    }
  }
  passwordMatch() {
    if (this.f.password_confirmation.touched) {
      if (this.f.password.value === this.f.password_confirmation.value) {
        this.f.password_confirmation.setErrors(null)
      } else {
        this.f.password_confirmation.setErrors({ not_matched: true })
      }
    }

  }
  onSubmit() {
    this.isLoading = true;
    this.formOpertaion();
    this.companyService.create(this.companyForm.value).subscribe(res => {
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Company is Created successfully.',
        showConfirmButton: false,
        timer: 1500
      }).then(result => {
        this.router.navigate(['/companies'], { queryParams: { page: 1 } })
      })
    }, err => {
      if (err.error.status === 400) {
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: err.error.data,
          showConfirmButton: false,
          timer: 1500
        })
      } else {
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: 'Something went wrong',
          showConfirmButton: false,
          timer: 1500
        })
      }
      this.isLoading = false
    })
  }
}
