import { FormManage } from 'src/app/shared/custom-validators';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { data } from 'jquery';
import { IPrice } from './../../shared/models/icompany';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';
import { CompaniesService } from '../../shared/services/companies.service';
import { ICompanyDetails } from '../../shared/models/icompany';
import { IPagination } from '../../../shared/models/response.model';
import { NgxSmartModalService } from 'ngx-smart-modal';

@Component({
  selector: 'app-view-prices',
  templateUrl: './view-prices.component.html',
  styleUrls: ['./view-prices.component.scss']
})
export class ViewPricesComponent extends FormManage implements OnInit {
  private id: number;
  prices: IPrice[];
  currentDiscount:IPrice;
  pagination: IPagination = {
    currentPage: 1,
    totalItems: 0,
    itemsPerPage: 12
  }
  
  // public users: ICompanyDetails;
  // public company;
  companyDiscountForm: FormGroup;
  isLoading: boolean = false;

  constructor(private ActivatedRoute: ActivatedRoute, private companyService: CompaniesService) { 
    super();
  }

  ngOnInit(): void {
    this.ActivatedRoute.paramMap.subscribe(param => {
      this.id = parseInt(param.get('id'))
      this.pagination.currentPage = parseInt(param.get('page'))
      // this.getProfile();
      // this.getUsers();
      this.getCompanyPricesHistory();
      
    })
    this.initForm();
  }

  initForm() {
    this.companyDiscountForm = new FormGroup({
      company_id: new FormControl(this.id, [Validators.required]),
      value: new FormControl('',[Validators.required]),
      is_percentage: new FormControl(''),
      labour_discount: new FormControl('',[Validators.required]),
      is_labour_percentage: new FormControl(0),
    })
    this.setForm(this.companyDiscountForm);
  }

  /////////////////////////
  getCompanyPricesHistory(){
    this.getCurrentDiscount();
    this.companyService.getPricesHistory(this.id, this.pagination.currentPage).subscribe(res=>{
      this.prices = res.data.data;
      this.pagination.totalItems = res.data?.total;
      this.pagination.itemsPerPage = res.data?.per_page;
      // console.log(this.prices);
    })
  }  
  getCurrentDiscount(){
    this.companyService.getCurrentDiscount(this.id).subscribe(res=>{
      this.currentDiscount = res.data;
    })
  }
  addNewDiscount(){
    let newDiscount = this.companyDiscountForm.value;
    newDiscount['is_percentage'] = newDiscount['is_percentage']  ? 1 : 0;
    newDiscount['is_labour_percentage'] = newDiscount['is_labour_percentage']  ? 1 : 0;
    // console.log(newDiscount);

    this.companyService.addNewCompanyDiscount(newDiscount).subscribe(res=>{
      // console.log(res);
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Successfull Operation.',
        showConfirmButton: false,
        timer: 1500
      }).then(result => {
        this.getCompanyPricesHistory();
        this.initForm();
      })      
    }, err => {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger mx-2'
        },
        buttonsStyling: false
      })
      swalWithBootstrapButtons.fire({
        title: 'Some thing went wrong!!',
        icon: 'error',
        showCancelButton: true,
        confirmButtonText: 'Retry',
        cancelButtonText: 'Cancel',
      }).then((result) => {
        if (result.isConfirmed) {
          this.getCompanyPricesHistory();
        }
      })
    }
    )    
  }
  /////////////////////////
}
