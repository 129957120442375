const AUTH = 'auth/';
const LOG_IN = 'login';
const CHANGE_PASSWORD = 'change-password';
const LOGOUT = 'logout';
const MANUFACTURER = 'manufacturer';
const AREAS = 'area';
const COUNTRY = 'country';
const SERVICE = 'service';
const SPAREPARTS = 'spare-part';
const CATEGORIES ='category';
const MODEL = 'model';
const MODEL_YEAR = 'year';
const RAGIONS = 'region';
const CUSTOMERS = 'customer';
const TECHNICIAN = 'technician';
const OPERATION = 'operation';
const BAN = 'ban';
const ACTIVATE = 'activate';
const Coupon = 'coupon';
const SLOT = 'slot';
const ORDER = 'order';
const RECALCULATE="recalculate"
const OREDR_HISTORY='user-orders'
const COMPLAINT ='complaint'
const RESPOND="respond"
const REVIEW="review"
const COMPANY="company"
const CAR_SPARE_PARTS="model-spare-part"

export abstract class EndPoints {
  // public static BASE_URL = BASE_URL;
  // public static API_URL = API_URL;
  public static LOG_IN_ENDPOINT = AUTH + LOG_IN;
  public static CHANGE_PASSWORD_ENDPOINT = AUTH + CHANGE_PASSWORD;
  public static LOGOUT_ENDPOINT = AUTH + LOGOUT;
  public static BAN_ENDPOINT = AUTH + BAN;
  public static ACTIVATE_ENDPOINT = AUTH + ACTIVATE;
  public static MANUFACTURER_ENDPOINT = MANUFACTURER;
  public static RAGIONS_ENDPOINT = RAGIONS;
  public static MODEL_ENDPOINT = MODEL;
  public static MODEL_YEAR_ENDPOINT = MODEL_YEAR;
  public static CUSTOMERS_ENDPOINT = CUSTOMERS;
  public static AREAS_ENDPOINT = AREAS;
  public static COUNTRY_ENDPOINT = COUNTRY;
  public static SERVICE_ENDPOINT = SERVICE;
  public static TECHNICIAN_ENDPOINT = TECHNICIAN;
  public static OPERATION_ENDPOINT = OPERATION;
  public static COUPON_ENDPOINT = Coupon;
  public static CAR_SPARE_PARTS_ENDPOINT = CAR_SPARE_PARTS;
  public static SPAREPARTS_ENDPOINT = SPAREPARTS;
  public static CATEGORIES_ENDPOINT = CATEGORIES;
  public static SLOT_ENDPOINT = SLOT;
  public static ORDER_ENDPOINT = ORDER;
  public static RECALCULATE_ENDPOINT = `${ORDER}/${RECALCULATE}`;
  public static OREDR_HISTORY_ENDPOINT = `${ORDER}/${OREDR_HISTORY}`;
  public static COMPLAINT_ENDPOINT = COMPLAINT;
  public static RESPOND_ENDPOINT = RESPOND;
  public static REVIEW_ENDPOINT = REVIEW;
  public static COMPANY_ENDPOINT = COMPANY;
}
