<div class="row">
  <div class="col-sm-12">
    <app-card [customHeader]="true" [options]="false">
       <div class="app-card-header">
        <h5 class="mb-3">
          <i class="fas fa-ticket-alt m-r-5"></i> {{'Generate Qr Code' | translate}}
        </h5>


      </div>
      <label> {{ "Link" | translate }}</label>
      <input
      [(ngModel)]="urlLink"
        type="text"
        class="form-control"
        [placeholder]="'Link' | translate"
      />
        <button class="btn btn-primary float-right f-14 mt-3" (click)="createQr()">
          {{'Submit' | translate}}
        </button>
        <a class="btn btn-danger float-right f-14 mt-3 mr-3" routerLink="../">
          {{'Cancel' | translate}}
        </a>
    </app-card>
  </div>
</div>

