import { LoggedInGuard } from './auth/shared/guards/logged-in.guard';
import { AuthComponent } from './layout/auth/auth.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminComponent } from './layout/admin/admin.component';
import { NotLoggedInGuard } from './auth/shared/guards/not-logged-in.guard';
import { PageNotFoundComponent } from './general/page-not-found/page-not-found.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
// import { LoggedInGuard } from './auth/shared/guards';
import { RefCompanyComponent } from './ref-company/ref-company.component';
import { CreateComponent } from './ref-company/create/create.component';
import { UpdateComponent } from './ref-company/update/update.component';
import { QrCodesComponent } from './qr-codes/qr-codes.component';
import { QrCreateComponent } from './qr-codes/create/create.component';

const routes: Routes = [
  {
    path: '',
    canActivate: [NotLoggedInGuard],
    component: AdminComponent,
    // canActivate: [LoggedInGuard],
    children: [
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full',
      },
      {
        path: 'dashboard',
        loadChildren: () =>
          import('./general/dashboard/dashboard.module').then(
            (module) => module.DashboardModule
          ),
      },
      {
        path: 'cars',
        loadChildren: () =>
          import('./cars/cars.module').then((module) => module.CarsModule),
      },
      {
        path: 'order',
        loadChildren: () =>
          import('./orders/orders.module').then(
            (module) => module.OrdersModule
          ),
      },
      {
        path: 'reports',
        loadChildren: () =>
          import('./reports/reports.module').then(
            (module) => module.ReportsModule
          ),
      },
      {
        path: 'region',
        loadChildren: () =>
          import('./region/regionandarea.module').then(
            (module) => module.RegionandareaModule
          ),
      },
      {
        path: 'services',
        loadChildren: () =>
          import('./services/services.module').then(
            (module) => module.ServicesModule
          ),
      },
      {
        path: 'review',
        loadChildren: () =>
          import('./reviews/reviews.module').then(
            (module) => module.ReviewsModule
          ),
      },
      {
        path: 'users',
        loadChildren: () =>
          import('./user/user.module').then((module) => module.UserModule),
      },
      {
        path: 'membership',
        loadChildren: () =>
          import('./membership/membership.module').then(
            (module) => module.MembershipModule
          ),
      },
      {
        path: 'notification',
        loadChildren: () =>
          import('./notifications/notifications.module').then(
            (module) => module.NotificationsModule
          ),
      },
      {
        path: 'payment',
        loadChildren: () =>
          import('./payment/payment.module').then(
            (module) => module.PaymentModule
          ),
      },
      {
        path: 'privacy',
        loadChildren: () =>
          import('./privacy/privacy.module').then(
            (module) => module.PrivacyModule
          ),
      },
      {
        path: 'terms',
        loadChildren: () =>
          import('./terms/terms.module').then((module) => module.TermsModule),
      },
      {
        path: 'companies',
        loadChildren: () =>
          import('./companies/companies.module').then(
            (module) => module.CompaniesModule
          ),
      },

      {
        path: 'contactUs',
        component: ContactUsComponent,
      },
      {
        path: 'qrCodes',
        component: QrCodesComponent,
      },
      {
        path: 'qrCodes/create',
        component: QrCreateComponent,
      },
      {
        path: 'refCompany',
        component: RefCompanyComponent,
      },
      {
        path: 'ref-create',
        component: CreateComponent,
      },
      {
        path: 'ref-update/:id',
        component: UpdateComponent,
      },
    ],
  },
  {
    path: '',
    component: AuthComponent,
    canActivate: [LoggedInGuard],
    children: [
      {
        path: 'auth',
        loadChildren: () =>
          import('./auth/auth.module').then(
            (module) => module.AuthenticationModule
          ),
      },
    ],
  },
  { path: '**', component: PageNotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
