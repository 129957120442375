import { Response } from './../../../shared/models/response.model';
import { HttpClientService } from './../../../shared/http-confing/http/http-client.service';

import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { EndPoints } from '../../../shared/endpoints/endpoints';
import { HttpHeaders } from '@angular/common/http';



@Injectable({
  providedIn: 'root'
})
export class CustomerService {
  apiURLCustomer = EndPoints.CUSTOMERS_ENDPOINT
  // private apiURL = 'http://192.168.1.8:8000';
  constructor(private https: HttpClientService, private router: Router) { }
  // lang?: string
  getAll(isPaginate: boolean = false,
    page?: number,
    search?: string): Observable<any> {
    if (!isPaginate) {
      page = null;
    }
    return this.https.get(`${this.apiURLCustomer}/all`, {
      is_paginate: `${+isPaginate}`,
      page,
      search,
    });

  }
  getById(Id) {
    return this.https.get(`${this.apiURLCustomer}/get`, { id: Id });
  }

  updateMemberShip(body) {
    return this.https.post(body, 'customer/update-customer-membership')
  }
  update(body) {
    return this.https.postFormData(body, `${this.apiURLCustomer}/update`);
  }
  updateUser(body){
    return this.https.post(body, `${this.apiURLCustomer}/update`);

  }
  delete(id) {
    return this.https.post({ id: id }, `${this.apiURLCustomer}/delete`);

  }
  create(body) {
    const headers = new HttpHeaders();
    // return this.https.post(`customer/customers`,body)
    return this.https.post(body, `customer/customers`)
  }
  exportExcel(): Observable<any>{
   return  this.https.get(`exports/users-excel`);
  }
}

