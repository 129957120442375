<div class="col-xl-12">
     <!-- <div class="app-card-header">
       <h5 class="mb-3">
         <i class="fas fa-ticket-alt m-r-5"></i>
         {{ "navigation.current_price" | translate }}
       </h5>
     </div> -->
     <div class="card" *ngIf="currentDiscount">
      <div class="row pb-0 table-active border-warning table-dark card-header justify-content-center align-items-center">
          <div class="col-md-4 col-12 text-center mr-md-5">
              <h2 class="mb-3 text-c-green">
                  {{ "navigation.current_discount" | translate }}
              </h2>
              <h3 *ngIf="currentDiscount?.is_percentage" class="jumbotron p-1">{{currentDiscount?.price}}%</h3>
              <h3 *ngIf="!currentDiscount?.is_percentage" class="jumbotron p-1">{{currentDiscount?.price |
                  currency:'EGP'}}</h3>
          </div>

          <div class="col-md-4 col-12 text-center">
            <h2 class="mb-3 text-c-green">
                {{ "navigation.current_labour_discount" | translate }}
            </h2>
            <h3 *ngIf="currentDiscount?.is_labour_percentage" class="jumbotron p-1">{{currentDiscount?.labour_discount}}%</h3>
            <h3 *ngIf="!currentDiscount?.is_labour_percentage" class="jumbotron p-1">{{currentDiscount?.labour_discount |
                currency:'EGP'}}</h3>
        </div>

      </div>
  </div>  

    <!-- /////add_discount_modal///// -->
  <!-- smart modal -->
  <ngx-smart-modal #addDiscountModal [closable]='false' customClass='custom-modal--max-width' identifier='addressdata'>
    <div class='modal-con'>
      <div class='close-modal'>
        <button (click)='addDiscountModal.close()' class='btn text-danger close-modal--icon ml-auto d-flex'>
          <i class='fas fa-times'></i>
        </button>
      </div>

      <div class="modal-header">       
        <h3>New Discount</h3>
      </div>

      <form [formGroup]="companyDiscountForm">
        <div class="row py-4 px-2 justify-content-center">
          <div class="col-md-6">
            <div class="form-group">
              <input
                formControlName="value"
                type="text"
                class="form-control"
                id="value"
                [placeholder]="
                  'forms.new_discount' | translate
                "
              />
              <app-field-error
                [displayError]="isFieldValid('value')"
                [errorMsg]="
                  'errors.mustnenumber'
                    | translate
                "
              >
              </app-field-error>
            </div>
          </div>

          <div class="col-md-6">
            <div class="form-group ml-md-3">
            <br>                        
              <label class="custom-control custom-checkbox d-block">
                  <input formControlName="is_percentage" type="checkbox" class="custom-control-input" name="validation-checkbox-custom">
                  <span class="custom-control-label font-weight-bold">{{"forms.is_percentage" | translate}}</span>
              </label>
            </div>
          </div>
        </div>
        <div class="row py-4 px-2 justify-content-center">
          <div class="col-md-6">
            <div class="form-group">
              <input
                formControlName="labour_discount"
                type="text"
                class="form-control"
                id="labour_discount"
                [placeholder]="
                  'forms.new_labour_discount' | translate
                "
              />
              <app-field-error
                [displayError]="isFieldValid('labour_discount')"
                [errorMsg]="
                  'errors.mustnenumber'
                    | translate
                "
              >
              </app-field-error>
            </div>
          </div>

          <div class="col-md-6">
            <div class="form-group ml-md-3">
            <br>                        
              <label class="custom-control custom-checkbox d-block">
                  <input formControlName="is_labour_percentage" type="checkbox" class="custom-control-input" name="validation-checkbox2-custom">
                  <span class="custom-control-label font-weight-bold">{{"forms.is_percentage" | translate}}</span>
              </label>
            </div>
          </div>
        </div>
      </form>
      <div class="modal-footer">       
        <button
          type="submit"
          [disabled]="companyDiscountForm.invalid"
          (click)="addNewDiscount();addDiscountModal.close()"
          data-dismiss="modal"
          class="btn btn-primary"
        >
          {{ "buttons.add" | translate }}
        </button>
        <button (click)='addDiscountModal.close()' type="button" class="btn btn-secondary" data-dismiss="modal">
          Cancel
        </button>
      </div>
    </div>
  </ngx-smart-modal>
<!-- ///////add_discount_modal////// -->
 
   <div class="card" [customHeader]="true" [options]="false" *ngIf="prices">
     <div class="card-header">
       <h5>
         <i class="fas fa-bars m-r-5"></i>
         {{ "navigation.company" | translate }}
         {{ "navigation.discounts_list" | translate }}
       </h5>
       <a class="btn btn-primary float-right align-items-center f-14" ngbTooltip="btn-primary"
          style="display: flex; margin: 5px"
          (click)="addDiscountModal.open()">
        <i class="fas fa-plus m-r-5"></i>{{ "buttons.create_new_discount" | translate }}
       </a>
     </div>
     <div class="table-responsive" *ngIf="prices">
       <table class="table table-striped">
         <thead>
           <tr>
             <th>{{ "forms.#" | translate }}</th>
             <th>{{ "forms.company_discounts" | translate }}</th>
             <th>{{ "forms.labour_discounts" | translate }}</th>
             <th>{{ "forms.created_at" | translate }}</th>
             <th>{{ "forms.updated_at" | translate }}</th>
             <th>{{ "forms.status" | translate }}</th>
           </tr>
         </thead>
         <tbody>
           <tr *ngFor="let price of prices;let i = index">
             <td>
               {{i+1}}
             </td>
             <td>
               <span *ngIf="!price.is_percentage"  class="font-weight-bold">                
                 {{price?.price | currency:'EGP'}}
               </span>
               <span *ngIf="price.is_percentage"  class="font-weight-bold">                
                {{price?.price}}%
              </span>
             </td>
             <td>
              <span *ngIf="!price.is_labour_percentage"  class="font-weight-bold">                
                {{price?.labour_discount | currency:'EGP'}}
              </span>
              <span *ngIf="price.is_labour_percentage"  class="font-weight-bold">                
               {{price?.labour_discount}}%
             </span>
            </td>
             <td>
               <span class="text-secondary font-weight-bold">
                 {{price?.created_at }}
               </span>
             </td>
             <td>
               <span class="text-secondary font-weight-bold">
                 {{price?.updated_at}}
               </span>
             </td>
             <td>
                <span *ngIf="price.active" class="badge badge-pill badge-success text-white  font-weight-bold">
                  Active
                </span>
                <span *ngIf="!price.active" class="badge badge-pill badge-danger text-white font-weight-bold">
                    Inactive
                  </span>
              </td>
           </tr>
         </tbody>
       </table>
     </div>
 
     <app-alert type="light text-center text-danger font-weight-bold" *ngIf="!prices.length">
       {{ "navigation.prices_list" | translate }}
       {{'errors.not_found' |translate}}
     </app-alert>
   </div>
 </div>