<div class="col-xl-12">
    <app-card [customHeader]="true" [options]="false">
        <div class="app-card-header">
            <h5 class="mb-3">
                <i class="fas fa-ticket-alt m-r-5"></i>
                {{ "navigation.orderlist" | translate }}
            </h5>
        </div>
        <div class="table-responsive" *ngIf="orders">
            <table class="table table-striped">
                <thead>
                    <tr>

                        <th>{{ "forms.#" | translate }}</th>
                        <th>{{ "forms.code" | translate }}</th>
                        <th>{{ "forms.status" | translate }}</th>
                        <th>{{ "forms.User GrandTotal" | translate }}</th>

                        <th>{{ "forms.created_at" | translate }}</th>


                        <th class="text-center">{{ "forms.actions" | translate }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of orders; let i = index">
                        <td>
                            {{i+1}}
                        </td>
                        <td>
                            <span class="text-secondary font-weight-bold">
                                {{item?.code }}
                            </span>
                        </td>
                        <td>
                            <span class="font-weight-bold"
                                [ngClass]="{'text-secondary': item?.status == 'PAID','text-success': item?.status == 'NEW','text-danger': item?.status == 'CANCELLED'}">
                                {{item?.status }}
                            </span>
                        </td>
                        <td>
                            <span class="text-secondary font-weight-bold">
                                {{item?.grand_total }}
                            </span>
                        </td>
                        <td>
                            <p> {{item?.created_at | date:'short'}} </p>
                        </td>
                        <td>
                            <div class="overlay-edit text-center">
                                <button type="button" class="btn btn-icon btn-outline-info"
                                    [routerLink]="['/order/order-list/details/' + item.id]">
                                    <i class="fas fa-eye"></i>
                                </button>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <app-alert *ngIf="!orders " type="danger  text-center"> {{'errors.nofound' |translate}} {{
            "navigation.orders" | translate }}
        </app-alert>

        <!-- <div class="row">
        <div class="col-12 text-center">
          <pagination-controls *ngIf="
          companies.length && pagination.totalItems > pagination.itemsPerPage
          " (pageChange)="pageChanged($event)" [nextLabel]="('buttons.next' | translate)"
            [previousLabel]="('buttons.previous' | translate)" screenReaderPaginationLabel="Pagination"
            screenReaderPageLabel="page" screenReaderCurrentLabel="You're on page">
          </pagination-controls>
        </div>
      </div> -->
    </app-card>
</div>