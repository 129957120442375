import { Injectable } from '@angular/core';
import { Ban } from './../model';
import { HttpClientService } from './../../../shared/http-confing/http/http-client.service';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { Response } from '../../../shared/models/response.model';
import { EndPoints } from '../../../shared/endpoints/endpoints';

@Injectable({
  providedIn: 'root'
})
export class ActivateUserService {
	apiURLActivate = EndPoints.ACTIVATE_ENDPOINT;


	constructor(private https: HttpClientService, private router: Router) { }


	activateUser(activate): Observable<Response<Ban>> {
		return this.https.post(activate,this.apiURLActivate );
	}



}