import { Injectable } from '@angular/core';
import { HttpClientService } from '../../../../app/shared/http-confing/http/http-client.service';
import { EndPoints } from '../../endpoints/endpoints';

@Injectable({
  providedIn: 'root',
})
export class OperationService {
  constructor(private http: HttpClientService) {}
  operationAPI = EndPoints.OPERATION_ENDPOINT;

  public createOperation(body) {
    return this.http.postFormData(body, `${this.operationAPI}/create`);
  }

  public getAllOperations(
    isPaginate: boolean = false,
    page?: number,
    search?: string
  ) {
    if (!isPaginate) {
      page = null;
    }
    return this.http.get(`${this.operationAPI}/all`, {
      is_paginate: `${+isPaginate}`,
      page,
      search,
    });
  }

  public getOperation(OperationId: number, isLocalization: boolean = false) {
    return this.http.get(`${this.operationAPI}/get`, {
      id: OperationId,
      is_localization: `${+isLocalization}`,
    });
  }

  public updateOperation(body) {
    return this.http.postFormData(body, `${this.operationAPI}/update`);
  }

  public deleteOperation(OperationId: number) {
    return this.http.post({ id: OperationId }, `${this.operationAPI}/delete`);
  }
}
