<div class="col-xl-12">
   <app-card [customHeader]="true" [options]="false" *ngIf="company">
    <div class="app-card-header">
      <h5 class="mb-3">
        <i class="fas fa-ticket-alt m-r-5"></i>
        {{ "navigation.company" | translate }}
        {{ "navigation.details" | translate }}
      </h5>
    </div>
    <div class="row">
      <div class="col-md-4 col-12">
        <h5 class="sub-title text-secondary">{{"forms.username" | translate}} </h5>
        <h6>{{company?.name}}</h6>
      </div>
      <div class="col-md-4 col-12">
        <h5 class="sub-title text-secondary">{{"forms.Email" | translate}} </h5>
        <h6>{{company?.email}}</h6>
      </div>
      <div class="col-md-4 col-12">
        <h5 class="sub-title text-secondary">{{"forms.phone" | translate}} </h5>
        <h6>{{company?.phone}}</h6>
      </div>

    </div>
    <div class="row py-4">
      <div class="col-md-4 col-12">
        <h5 class="sub-title text-secondary">{{"forms.created_at" | translate}} </h5>
        <h6>
          <span> {{company?.created_at | date:'fullDate'}} </span>
          <span class="mx-2 px-2 border-left border-dark"> {{company?.created_at |
            date:'mediumTime'}} </span>
        </h6>
      </div>
      <div class="col-md-4 col-12">
        <h5 class="sub-title text-secondary">{{"forms.verified_code" | translate}} </h5>
        <h6>{{company?.verified_code}}</h6>
      </div>
      <div class="col-md-4 col-12" *ngIf="company.company_user && company.company_user.phone_verified_at">
        <h5 class="sub-title text-secondary">{{"forms.verified_at" | translate}} </h5>
        <h6>
          <span> {{company?.phone_verified_at | date:'fullDate'}} </span>
          <span class="mx-2 px-2 border-left border-dark"> {{company?.created_at |
            date:'mediumTime'}} </span>
        </h6>
      </div>
    </div>

  </app-card> 
  <app-card [customHeader]="true" [options]="false" *ngIf="users">
    <div class="app-card-header">
      <h5>
        <i class="fas fa-bars m-r-5"></i>
        {{ "navigation.company" | translate }}
        {{ "navigation.users" | translate }}
      </h5>
    </div>
    <div class="table-responsive" *ngIf="users">
      <table class="table table-striped">
        <thead>
          <tr>
            <th>{{ "forms.#" | translate }}</th>
            <th>{{ "forms.name" | translate }}</th>
            <th>{{ "forms.phone" | translate }}</th>
            <th>{{ "forms.Email" | translate }}</th>
            <th>{{ "forms.actions" | translate }}</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let user of users;let i = index">
            <td>
              {{i+1}}
            </td>
            <td>
              <span class="text-secondary font-weight-bold">
                {{user?.name | slice : 0 : 15}}
              </span>
            </td>
            <td>
              <span class="text-secondary font-weight-bold">
                {{user?.phone }}
              </span>
            </td>
            <td>
              <span class="text-secondary font-weight-bold">
                {{user?.email | slice : 0 : 15}}
              </span>
            </td>
            <td>
              <div class="overlay-edit">
                <button type="button" class="btn btn-icon btn-outline-success"
                  [routerLink]="['/users/customer-show/' + user.id]">
                  <i class="fas fa-eye"></i>
                </button>
                <button type="button" class="btn btn-icon btn-outline-primary mx-2"
                  [routerLink]="['/companies/user-orders/' + user.id]">
                  <i class="fas fa-shopping-cart"></i>
                </button>
              </div>
            </td>
          </tr>
          <!-- <tr *ngFor="
                      let company of companies?.my_users 
                      let i = index
                    ">
                  <td>
                    {{i+1}}
                  </td>
                  <td>
                    <span class="text-secondary font-weight-bold">{{company?.user?.name | slice : 0 : 15}}</span>
                  </td>
                  <td>
                    <span class="text-secondary font-weight-bold">{{company?.user?.phone | slice : 0 : 15}}</span>
      
                  </td>
                  <td>
                    <span class="text-secondary font-weight-bold">{{company?.user?.email | slice : 0 : 15}}</span>
      
                  </td>
                  <td>
                    <span class="text-secondary font-weight-bold" *ngIf="company?.is_percentage == 1 "> {{company?.value}} %
                    </span>
                    <span class="text-secondary font-weight-bold" *ngIf="company?.is_percentage == 0 "> {{company?.value}} EGP
                    </span>
      
                  </td>
                  <td>
                    <span class="text-secondary font-weight-bold">{{company?.key | slice : 0 : 15}}</span>
      
                  </td>
                  <td>
                    <div class="overlay-edit text-center">
                      <button type="button" class="btn btn-icon btn-outline-success"
                        [routerLink]="['/companies/update/' + company.id]">
                        <i class="fas fa-edit"></i>
                      </button>
      
      
                      <button type="button" class="btn btn-icon btn-outline-danger mx-2" *ngIf="company?.active == 1"
                        (click)="confirmBan(company.id,0)" title="Ban">
                        <i class="fas fa-times fa-lg"></i>
                      </button>
                      <button type="button" class="btn btn-icon btn-outline-warning mx-2" *ngIf="company?.active == 0"
                        (click)="confirmBan(company.id,1)" title="Allow">
                        <i class="fas fa-check"></i>
                      </button>
                      <button type="button" class="btn btn-icon btn-outline-info"
                        [routerLink]="['/companies/view/' + company.id]">
                        <i class="fas fa-eye"></i>
                      </button>
                    </div>
                  </td>
                </tr> -->
        </tbody>
      </table>
    </div>

  </app-card>
  <app-alert type="light text-center text-danger font-weight-bold" *ngIf="!users">
    {{ "navigation.company" | translate }}
    {{'errors.not_found' |translate}}
  </app-alert>
</div>