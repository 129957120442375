import { ViewPricesComponent } from './components/view-prices/view-prices.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CompaniesComponent } from './components/companies/companies.component';
import { CreateComponent } from './components/create/create.component';
import { ViewComponent } from './components/view/view.component';
import { UpdateComponent } from './components/update/update.component';
import { OrdersComponent } from './components/orders/orders.component';

const routes: Routes = [
  {
    path: '',
    component: CompaniesComponent
  }, {
    path: 'create',
    component: CreateComponent
  },{
    path:'view/:id',
    component:ViewComponent
  },{
    path:'view-discounts/:id',
    component:ViewPricesComponent
  },{
    path:'update/:id',
    component:UpdateComponent
  },{
    path:'user-orders/:id',
    component:OrdersComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CompaniesRoutingModule { }
