import { Injectable } from '@angular/core';
import { HttpClientService } from '../../../../app/shared/http-confing/http/http-client.service';
import { EndPoints } from '../../endpoints/endpoints';

@Injectable({
  providedIn: 'root',
})
export class AreasService {
  constructor(private http: HttpClientService) {}
  areasAPI = EndPoints.AREAS_ENDPOINT;

  public createAreas(body: {
    name_en: string;
    name_ar: string;
    long: number;
    lat: number;
    radius: number;
    region_id: number;
  }) {
    return this.http.postFormData(body, `${this.areasAPI}/create`);
  }

  public getAllAreas(
    isPaginate: boolean = false,
    page?: number,
    active?: number,
    regionId?: number,
    search?: string
  ) {
    if (!isPaginate) {
      page = null;
    }
    return this.http.get(`${this.areasAPI}/all`, {
      is_paginate: `${+isPaginate}`,
      page,
      active,
      region_id: regionId,
      search,
    });
  }
  public getAreasByCity(cityId: number) {
    return this.http.get(`${this.areasAPI}/all`, { region_id: cityId });
  }
  public getAllAreasFilter(body) {
    return this.http.get(`${this.areasAPI}/all`, body);
  }

  public getAreas(regionId: number, isLocalization: boolean = false , orderId?:number) {
    return this.http.get(`${this.areasAPI}/get`, {
      id: regionId,
      is_localization: `${+isLocalization}`,
      order_id: orderId
    });
  }

  public updateAreas(body) {
    return this.http.postFormData(body, `${this.areasAPI}/update`);
  }

  public deleteAreas(regionId: number) {
    return this.http.post({ id: regionId }, `${this.areasAPI}/delete`);
  }
}


export interface ImodelAreaOptions {
  active?: number;
  country_id?: number;
  page?: number;
  is_paginate: 1 | 0;
  region_id?: number;

}
