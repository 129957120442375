import { Injectable } from '@angular/core';
import { Params } from '@angular/router';

export interface NavigationItem {
  id: string;
  title: string;
  type: 'item' | 'collapse' | 'group';
  translate?: string;
  icon?: string;
  hidden?: boolean;
  url?: string;
  classes?: string;
  exactMatch?: boolean;
  external?: boolean;
  target?: boolean;
  breadcrumbs?: boolean;
  function?: any;
  badge?: {
    title?: string;
    type?: string;
  };
  children?: Navigation[];
  qParams?: Params;
}

export interface Navigation extends NavigationItem {
  children?: NavigationItem[];
}

const NavigationItems = [
  {
    id: 'navigation',
    title: 'navigation.navigation',
    type: 'group',
    icon: 'feather icon-monitor',
    children: [
      {
        id: 'cars',
        title: 'navigation.cars',
        type: 'collapse',
        icon: 'feather icon-home',
        children: [
          {
            id: 'manufacturer',
            title: 'navigation.manufacturer',
            type: 'item',
            url: '/cars/manufacturer-list',
          },
          {
            id: 'model',
            title: 'navigation.model',
            type: 'item',
            url: '/cars/model-list',
          },
          {
            id: 'model-year',
            title: 'navigation.modelyear',
            type: 'item',
            url: '/cars/model/year-list',
          },
        ],
      },
      {
        id: 'regions',
        title: 'navigation.regions',
        type: 'collapse',
        icon: 'feather icon-home',
        children: [
          {
            id: 'country',
            title: 'navigation.country',
            type: 'item',
            url: '/region/country-list',
          },
          {
            id: 'city',
            title: 'navigation.city',
            type: 'item',
            url: '/region/region-list',
          },
          {
            id: 'area',
            title: 'navigation.areas',
            type: 'item',
            url: '/region/areas-list',
          },
        ],
      },
      {
        id: 'users',
        title: 'navigation.users',
        type: 'collapse',
        icon: 'feather icon-home',
        children: [
          {
            id: 'customer-list',
            title: 'navigation.customerlist',
            type: 'item',
            url: 'users/customer-list',
          },
          {
            id: 'operation-list',
            title: 'navigation.operationlist',
            type: 'item',
            url: 'users/operation-list',
          },
          {
            id: 'technician-list',
            title: 'navigation.technicianlist',
            type: 'item',
            url: 'users/technician-list',
          },
        ],
      },
      {
        id: 'orders',
        title: 'navigation.orders',
        type: 'collapse',
        icon: 'feather icon-home',
        children: [
          {
            id: 'coupon-list',
            title: 'navigation.coupon',
            type: 'item',
            url: 'order/coupon',
          },
          {
            id: 'slot-list',
            title: 'navigation.slot',
            type: 'item',
            url: 'order/slot-list',
          },
          {
            id: 'order-list',
            title: 'navigation.orders',
            type: 'item',
            url: 'order/order-list',
          },
          {
            id: 'assign-order',
            title: 'navigation.Assign-orders',
            type: 'item',
            url: 'order/order-list/assign',
          },
          {
            id: 'waiting-order',
            title: 'navigation.Waiting-orders',
            type: 'item',
            url: 'order/order-list/waiting',
          },
          {
            id: 'requets-orders',
            title: 'Requests orders',
            type: 'item',
            url: 'order/order-list/requests',
          },
        ],
      },
      {
        id: 'services',
        title: 'navigation.services',
        type: 'collapse',
        icon: 'feather icon-home',
        children: [
          {
            id: 'categories',
            title: 'Categories',
            type: 'item',
            url: '/services/categories',
          },
          {
            id: 'navigation.services',
            title: 'navigation.services',
            type: 'item',
            url: '/services/list',
          },
          {
            id: 'spareparts-list',
            title: 'navigation.SpareParts',
            type: 'item',
            url: '/services/spareparts-list',
          },
          {
            id: 'navigation.bulkUpdate',
            title: 'navigation.bulkUpdate',
            type: 'item',
            url: '/services/bulk-update',
          },
          {
            id: 'navigation.Car_Spare_Parts',
            title: 'navigation.Car_Spare_Parts',
            type: 'item',
            url: '/services/car-spareparts',
          },
        ],
      },
      {
        id: 'contactUs',
        title: 'navigation.contactUs',
        type: 'item',
        icon: 'feather icon-home',
        url: '/contactUs',
      },
      {
        id: 'qrCodes',
        title: 'Qr Codes',
        type: 'item',
        icon: 'feather icon-home',
        url: '/qrCodes',
      },

      {
        id: 'refCompany',
        title: 'refCompany',
        type: 'item',
        icon: 'feather icon-home',
        url: '/refCompany',
      },
      {
        id: 'notifications',
        title: 'navigation.notifications',
        type: 'collapse',
        icon: 'feather icon-home',
        children: [
          {
            id: 'navigation.Customer-Notification',
            title: 'navigation.Customer-Notification',
            type: 'item',
            url: '/notification/customer-notification',
          },
          {
            id: 'navigation.technician-Notification',
            title: 'navigation.technician-Notification',
            type: 'item',
            url: '/notification/technician-notification',
          },
        ],
      },
      {
        id: 'reviews',
        title: 'navigation.reviews',
        type: 'collapse',
        icon: 'feather icon-home',
        children: [
          {
            id: 'navigation.compliant',
            title: 'navigation.compliant',
            type: 'item',
            url: '/review/complaint-list',
          },
        ],
      },
      {
        id: 'reports',
        title: 'navigation.reports',
        type: 'collapse',
        icon: 'feather icon-home',
        children: [
          {
            id: 'navigation.PartsReport',
            title: 'navigation.PartsReport',
            type: 'item',
            url: '/reports/parts',
          },
          {
            id: 'navigation.ServicesReport',
            title: 'navigation.ServicesReport',
            type: 'item',
            url: '/reports/services',
          },
        ],
      },
      {
        id: 'companies',
        title: 'navigation.companies',
        type: 'item',
        icon: 'feather icon-home',
        url: '/companies',
        qParams: {
          page: 1,
        },
      },
      {
        id: 'membership',
        title: 'navigation.memberships',
        type: 'item',
        icon: 'feather icon-home',
        url: '/membership',
      },

      {
        id: 'payment',
        title: 'navigation.payment',
        type: 'collapse',
        icon: 'feather icon-home',
        children: [
          {
            id: 'payment-list',
            title: 'Payment List',
            type: 'item',
            url: '/payment',
          },
          {
            id: 'bank',
            title: 'Bank List',
            type: 'item',
            url: '/payment/banks',
          },
        ],
      },
      {
        id: 'privacy',
        title: 'navigation.privacy',
        type: 'item',
        icon: 'feather icon-home',
        url: '/privacy',
      },
      {
        id: 'terms',
        title: 'navigation.terms',
        type: 'item',
        icon: 'feather icon-home',
        url: '/terms',
      },
    ],
  },
];
const operationNavigationItems = [
  {
    id: 'navigation',
    title: 'navigation.navigation',
    type: 'group',
    icon: 'feather icon-monitor',
    children: [
      {
        id: 'users',
        title: 'navigation.users',
        type: 'collapse',
        icon: 'feather icon-home',
        children: [
          {
            id: 'customer-list',
            title: 'navigation.customerlist',
            type: 'item',
            url: 'users/customer-list',
          },
          // {
          //   id: 'operation-list',
          //   title: 'navigation.operationlist',
          //   type: 'item',
          //   url: 'users/operation-list',
          // },
          // {
          //   id: 'technician-list',
          //   title: 'navigation.technicianlist',
          //   type: 'item',
          //   url: 'users/technician-list',
          // },
          {
            id: 'create-user',
            url: 'users/user-create',
          },
          {
            id: 'update-user',
            url: 'users/user-update/:id',
          },
        ],
      },
      {
        id: 'orders',
        title: 'navigation.orders',
        type: 'collapse',
        icon: 'feather icon-home',
        children: [
          // {
          //   id: 'coupon-list',
          //   title: 'navigation.coupon',
          //   type: 'item',
          //   url: 'order/coupon',
          // },
          // {
          //   id: 'slot-list',
          //   title: 'navigation.slot',
          //   type: 'item',
          //   url: 'order/slot-list',
          // },
          {
            id: 'order-list',
            title: 'navigation.orders',
            type: 'item',
            url: 'order/order-list',
          },
          // {
          //   id: 'assign-order',
          //   title: 'navigation.Assign-orders',
          //   type: 'item',
          //   url: 'order/order-list/assign',
          // },
          // {
          //   id: 'waiting-order',
          //   title: 'navigation.Waiting-orders',
          //   type: 'item',
          //   url: 'order/order-list/waiting',
          // },
          {
            id: 'requets-orders',
            title: 'Requests Orders',
            type: 'item',
            url: 'order/order-list/requests',
          },
        ],
      },
      {
        id: 'reviews',
        title: 'navigation.reviews',
        type: 'collapse',
        icon: 'feather icon-home',
        children: [
          {
            id: 'navigation.compliant',
            title: 'navigation.compliant',
            type: 'item',
            url: '/review/complaint-list',
          },
        ],
      },
    ],
  },
];
const emptyNavigationItems = [
  {
    id: 'navigation',
    title: 'navigation.navigation',
    type: 'group',
    icon: 'feather icon-monitor',
    children: [],
  },
];
const user = JSON.parse(localStorage.getItem('user'));
@Injectable()
export class NavigationItem {
  public get() {
    if (user.user_type === 'Admin') {
      return NavigationItems;
    } else if (user.user_type === 'Operation Manger') {
      return operationNavigationItems;
    } else {
      return emptyNavigationItems;
    }
  }
}
