<div class="col-xl-12">
  <app-card [customHeader]="true" [options]="false">
    <div class="app-card-header">
      <h5 class="mb-3">
        {{ "Qr Codes" | translate }}
      </h5>
      <a
        class="btn btn-primary float-right f-14"
        routerLink="/qrCodes/create"
      >
        <i class="fas fa-plus m-r-5"></i>{{ "buttons.create" | translate }}
      </a>
      <div class="table-responsive mt-3">
        <table class="table table-striped">
          <thead>
            <tr>
              <th>{{ "link" | translate }}</th>

              <th>{{ "forms.image" | translate }}</th>
              <th>{{ "forms.actions" | translate }}</th>
            </tr>
          </thead>
          <tbody>
            <tr
              *ngFor="
                let qr of qrList | paginate : paginationOptions;
                let i = index
              "
            >
              <td>{{ qr.link }}</td>
              <td>
                <img
                  [src]=qr.qr_code
                  (error)="onImgError($event)"
                  height="100px"
                  width="100px"
                  alt=""
                />
              </td>
              <td>
                <a href={{qr.qr_code}} target="_blank"  class="btn btn-primary f-14" download>Download Qr</a>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="row">
          <div class="col-12 text-center">
            <pagination-controls
              (pageChange)="pageChanged($event)"
              previousLabel="Previous"
              nextLabel="Next"
              screenReaderPaginationLabel="Pagination"
              screenReaderPageLabel="page"
              screenReaderCurrentLabel="You're on page"
            >
            </pagination-controls>
          </div>
        </div>
      </div>
    </div>
  </app-card>
</div>
