<app-card [cardTitle]="('forms.create company' | translate)" [options]="false">
  <form [formGroup]="companyForm" (ngSubmit)="confirm(companyForm)">
    <div class="row">
      <div class="col-md-6 col-12 ">
        <h5 class="sub-title">{{"Name" | translate}} <span class="text-danger"> * </span> </h5>
        <input class="form-control form-control-sm" type="text" placeholder="Enter Ref Company name" rqeuired
          formControlName="name" />
        <app-field-error [displayError]="isFieldValid('name')"
          errorMsg="Please enter a valid name with more than 2 characters">
        </app-field-error>
      </div>
      <div class="col-md-6 col-12">
        <h5 class="sub-title">{{"forms.Email" | translate}}</h5>
        <input class="form-control form-control-sm" type="text" placeholder="Enter Email" rqeuired
          formControlName="email" />
        <app-field-error [displayError]="isFieldValid('email')" [errorMsg]="'errors.entervalidemail' | translate">
        </app-field-error>
      </div>

    </div>
    <!-- <div class="row py-3">
      <div class="col-md-6 col-12">
        <h5 class="sub-title">{{"forms.password" | translate}}<span class="text-danger"> * </span></h5>
        <input class="form-control form-control-sm" type="password" autocomplete="off" placeholder="Enter password"
          rqeuired formControlName="password" (blur)="passwordMatch()" />
        <app-field-error [displayError]="isFieldValid('password')"
          errorMsg="Please enter a valid name with more than 2 characters">
        </app-field-error>
      </div>
      <div class="col-md-6 col-12">
        <h5 class="sub-title">{{"forms.c_password" | translate}}<span class="text-danger"> * </span></h5>
        <input class="form-control form-control-sm" type="password" autocomplete="off"
          placeholder="Enter your password again" rqeuired formControlName="password_confirmation"
          (blur)="passwordMatch()" />
        <app-field-error
          [displayError]="f.password_confirmation.errors?.not_matched || isFieldValid('password_confirmation')"
          [errorMsg]="'errors.passworddoesntmatch' | translate">
        </app-field-error>
      </div>
    </div> -->
    <div class="row py-3">
      <!-- <div class="col-md-6 col-12">
        <h5 class="sub-title">{{"forms.phone" | translate}}<span class="text-danger"> * </span></h5>
        <input formControlName="phone" type="text" class="form-control form-control-sm"
          placeholder="Enter phone number" />
        <app-field-error [displayError]="isFieldValid('phone')" errorMsg="Please Enter a valid phone">
        </app-field-error>
      </div> -->

    </div>

    <div class="row py-3">
      <!-- <div class="col-md-6 col-12 ">
        <div class="row">
          <div class="col-5">
            <h5 class="sub-title">{{"forms.service_discount" | translate}}</h5>
            <input class="form-control form-control-sm" type="numbers" placeholder="Enter discount" rqeuired
              formControlName="value" />
            <app-field-error [displayError]="isFieldValid('value')"
              errorMsg="Please enter a valid price"></app-field-error>
          </div>
          <div class="col-7">
            <div class="form-group d-flex align-items-center">
              <div class="switch switch-primary d-inline m-r-10">
                <input type="checkbox" id="switch-p-1" formControlName="is_percentage">
                <label for="switch-p-1" class="cr"></label>
              </div>
              <h5 class="sub-title pt-2 m-0">{{"forms.Percentage" | translate}} ?</h5>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-12 ">
        <div class="row">
          <div class="col-5">
            <h5 class="sub-title">{{"forms.labour_discount" | translate}}</h5>
            <input class="form-control form-control-sm" type="numbers" placeholder="Enter discount" rqeuired
              formControlName="labour_discount" />
            <app-field-error [displayError]="isFieldValid('labour_discount')"
              errorMsg="Please enter a valid Price"></app-field-error>
          </div>
          <div class="col-7">
            <div class="form-group d-flex align-items-center">
              <div class="switch switch-primary d-inline m-r-10">
                <input type="checkbox" id="switch-p-2" formControlName="is_labour_percentage">
                <label for="switch-p-2" class="cr"></label>
              </div>
              <h5 class="sub-title pt-2 m-0">{{"forms.Percentage" | translate}} ?</h5>
            </div>
          </div>
        </div>
      </div> -->
      <div class="col-md-6 col-12">
        <label for="ref_membership_id">Membership</label>
      <select name="ref_membership_id" id="membership_id" class="form-control" formControlName="ref_membership_id" >
        <option value="" disabled selected>Select Membership</option>
        <option [value]="membership.id"  *ngFor="let membership of membershipList">{{membership.name}}</option>
      </select>
      </div>
      <div class="col-md-6 col-12">
        <label for="ref_wallet_offer">Wallet offer</label>
        <input type="number" class="form-control " id="ref_wallet_offer" formControlName="ref_wallet_offer"  placeholder="Enter wallet offer" rqeuired>

      </div>
      <div class="col-md-6 col-12 mt-3">
        <label for="code">Code</label>
        <input type="text" class="form-control " id="code" formControlName="code"  placeholder="Enter code" rqeuired>

      </div>
    </div>


    <div class="row mt-5">
      <div class="col-2">
        <div form-submit-btn [btnLable]="('buttons.Submit' | translate)" [formInvalid]="companyForm.invalid"
          [isLoading]="isLoading" type="submit">
        </div>
      </div>
      <div class="col-2">
        <div form-submit-btn [btnLable]="('cancel' | translate)"
          type="button" routerLink="/refCompany">
        </div>
      </div>
    </div>
  </form>
</app-card>
