import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { ActivatedRoute, Router } from '@angular/router';
import { CompaniesService } from '../../shared/services/companies.service';
import { ICompany } from '../../shared/models/icompany';
import { FormManage } from '../../../shared/custom-validators/form-handling.validator';

@Component({
  selector: 'app-update',
  templateUrl: './update.component.html',
  styleUrls: ['./update.component.scss']
})
export class UpdateComponent extends FormManage implements OnInit {
  public id: number;
  companyForm: FormGroup;
  isLoading: boolean = false;
  public company: ICompany;
  constructor(private router: Router, private ActivatedRoute: ActivatedRoute, private companyService: CompaniesService) {
    super();
  }

  ngOnInit(): void {
    this.ActivatedRoute.paramMap.subscribe(param => {
      this.id = parseInt(param.get('id'))
      this.getCompanyById();
    })
  }

  getCompanyById() {
    this.companyService.getById(this.id).subscribe(res => {
      this.company = res.data
      this.initForm()
    }, err => {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: 'btn btn-success font-weight-bold rounded-lg',
          cancelButton: 'btn btn-danger mx-3 font-weight-bold rounded-lg'
        },
        buttonsStyling: false
      })
      swalWithBootstrapButtons.fire({
        title: 'Something Went wrong',
        icon: 'error',
        showCancelButton: true,
        confirmButtonText: 'Retry',
        cancelButtonText: 'Cancel',
      }).then((result) => {
        if (result.isConfirmed) {
          this.getCompanyById();
        }
      })
    })

  }
  initForm() {
    this.companyForm = new FormGroup({
      id:new FormControl(this.company?.user_id || null),
      name: new FormControl(this.company?.user?.['name'] || 'Not Choosed yet', [Validators.required, Validators.minLength(2)]),
      email: new FormControl(this.company?.email || '', [Validators.pattern(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]),
      password: new FormControl('', [ Validators.minLength(8), Validators.maxLength(22)]),
    })
    this.setForm(this.companyForm)
  }

  get f() {
    return this.companyForm.controls;
  }

  confirm() {
    if (this.companyForm.invalid) {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'Please fill the required data.',
        showConfirmButton: false,
        timer: 1500
      })
      this.companyForm.markAllAsTouched();
    } else {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger mx-2'
        },
        buttonsStyling: false
      })
      swalWithBootstrapButtons.fire({
        title: 'Are you Sure?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Update',
        cancelButtonText: 'Cancel',
      }).then((result) => {
        if (result.isConfirmed) {
          this.onSubmit();
        }
      })
    }
  }
  formOperation(obj:{}) {
    if (obj['password'] === '') {
      delete obj['password']
    }
    return obj;
  }
  onSubmit() {
    this.isLoading = true;
     let formData = this.formOperation(this.companyForm.value);
    this.companyService.update(formData).subscribe(res=>{
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'Company is Updated successfully.',
          showConfirmButton: false,
          timer: 1500
        }).then(result => {
          this.router.navigate(['/companies'], { queryParams: { page: 1 } })
        })
      
    },err=>{
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger mx-2'
        },
        buttonsStyling: false
      })
      swalWithBootstrapButtons.fire({
        title: 'Some thing went wrong!!',
        icon: 'error',
        showCancelButton: true,
        confirmButtonText: 'Retry',
        cancelButtonText: 'Cancel',
      }).then((result) => {
        if (result.isConfirmed) {
          this.onSubmit();
        }
      })
    })

  }

}
