import { catchError } from 'rxjs/operators';
import { Response } from './../../../shared/models/response.model';
import { HttpClientService } from './../../../shared/http-confing/http/http-client.service';
import { EndPoints } from '../../../shared/endpoints/endpoints';
import { Observable, of, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { SlotModel } from '../models';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class SlotService {
  apiURL = EndPoints.SLOT_ENDPOINT;

  constructor(private https: HttpClientService, private router: Router) { }
  // lang?: string
  getAll(
    isPaginate: boolean = false,
    page?: number,
    active?: number,
    search?: string
  ) {
    if (!isPaginate) {
      page = null;
    }

    return this.https.get(`${this.apiURL}/all`, {
      is_paginate: `${+isPaginate}`,
      page,
      active,
      search,
    });
  }
  getSlotsByDay(day:number):Observable<any>{
    return this.https.get(`${this.apiURL}/all`,{weekday:day})
  }
  getAllFilter(body) {
    return this.https.get(`${this.apiURL}/all`, body);
  }
  getAllByManufacturer(manufacturerId: number) {
    return this.https.get(`${this.apiURL}/all`, {
      manufacturer_id: manufacturerId,
    });
  }
  getById(Id): Observable<Response<SlotModel>> {
    return this.https.get(`${this.apiURL}/get`, { id: Id });
  }
  getAllWeekDays(): Observable<Response<any>> {
    return this.https.get(`${this.apiURL}/weekday`)
  }
  create(payLoad): Observable<Response<SlotModel>> {
    return this.https.postFormData(payLoad, `${this.apiURL}/create`).pipe(
      catchError(error => {
        let errorMsg: string;
        if (error.error instanceof ErrorEvent) {
          errorMsg = `Error: ${error.error.message}`;
        }

        return throwError(errorMsg);
      })
    );
  }

  update(payLoad): Observable<Response<SlotModel>> {
    return this.https.postFormData(payLoad, `${this.apiURL}/update`);
  }
  delete(id): Observable<Response<SlotModel>> {
    return this.https.post({ id: id }, `${this.apiURL}/delete`);
  }
}

export interface ImodelSlotOptions {
  page?: number;
  is_paginate: 1 | 0;
  region_id?: number;
  area_id?: number;
  start_time?: any;
  end_time?: any;
}
