import { Response } from './../../../shared/models/response.model';
import { HttpClientService } from './../../../shared/http-confing/http/http-client.service';
import { EndPoints } from '../../../shared/endpoints/endpoints';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { CarSparePartsModel } from '../models';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CarSparePartsService {
  apiURLModel = EndPoints.CAR_SPARE_PARTS_ENDPOINT;

  private sparePartName = new BehaviorSubject<string>('');

  // get sparePartName$(): Observable<string> {
  //   return this.sparePartName.asObservable();
  // }

  get dataSparePartName(): string {
    return this.sparePartName.value;
  }

  addSparePartName(name: string) {
    this.sparePartName.next(name);
  }

  clearSparePartName() {
    this.sparePartName.next('');
  }

  constructor(private https: HttpClientService, private router: Router) {}
  // lang?: string
  getAll(
    isPaginate: boolean = false,
    page?: number,
    active?: number,
    search?: string
  ) {
    if (!isPaginate) {
      page = null;
    }

    return this.https.get(`${this.apiURLModel}/all`, {
      is_paginate: `${+isPaginate}`,
      page,
      active,
      search,
    });
  }

  create(payLoad): Observable<Response<CarSparePartsModel>> {
    return this.https.postFormData(payLoad, `${this.apiURLModel}/create`);
  }

  update(payLoad): Observable<Response<CarSparePartsModel>> {
    return this.https.postFormData(payLoad, `${this.apiURLModel}/update`);
  }

  getSparePartsPrice(id){
    return this.https.get(`${this.apiURLModel}/${id}/prices`);
  }

  getSparePartsModel(id,price){
    return this.https.get(`${this.apiURLModel}/${id}/models`,{price:price});
  }

  getSparePartModelPrice(modelId: number,sparePartsId: number) {
    return this.https.get(`${this.apiURLModel}/pricePerCondition/${modelId}/${sparePartsId}`);
  }

  // getById(Id): Observable<Response<CarSparePartsModel>> {
  //   return this.https.post( { id: Id },`${this.apiURLModel}/update`);
  // }

  // delete(id): Observable<Response<CarSparePartsModel>> {
  //   return this.https.post({ id: id }, `${this.apiURLModel}/delete`);
  // }

  // getAllFilter(body) {
  //   return this.https.get(`${this.apiURLModel}/all`, body);
  // }
  // getAllByManufacturer(manufacturerId: number) {
  //   return this.https.get(`${this.apiURLModel}/all`, {
  //     manufacturer_id: manufacturerId,
  //   });
  // }
  // // : Observable<Response<Data<Cars[]>>>


  // getSelectedServiceModels(serviceModelsIds: any) {
  //   return this.https.get(`${this.apiURLModel}/bulk-get`, {
  //     service_model_ids: serviceModelsIds,
  //   });
  // }
  // updateMultiServiceModels(payLoad) {
  //   return this.https.postFormData(payLoad, `service/bulk-edit-spare-parts`);
  // }
}
