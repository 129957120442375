import { Injectable } from '@angular/core';
import { HttpClientService } from '../../../../app/shared/http-confing/http/http-client.service';
import { EndPoints } from '../../endpoints/endpoints';

@Injectable({
  providedIn: 'root',
})
export class ComplaintService {
  constructor(private http: HttpClientService) {}
  complaintAPI = EndPoints.COMPLAINT_ENDPOINT;
  respondApi=EndPoints.RESPOND_ENDPOINT

  public getAll(
  isPaginate: boolean = false,
    page?: number
  ) {
    if (!isPaginate) {
      page = null;
    }
    return this.http.get(`${this.complaintAPI}/all`, {
      is_paginate: `${+isPaginate}`,
      page,
    });
  }

  public getById(complaintId: number, isLocalization: boolean = false) {
    return this.http.get(`${this.complaintAPI}/get`, {
      id: complaintId,
      is_localization: `${+isLocalization}`,
    });
  }
  createRespond(body,complainId){
    return this.http.post(body,`${this.respondApi}/create`,{complain_id:complainId})
  }

}
