import { Injectable } from '@angular/core';
import { HttpClientService } from '../../http-confing/http/http-client.service';
import { EndPoints } from '../../endpoints/endpoints';

@Injectable({
  providedIn: 'root',
})
export class ServicesService {
  constructor(private http: HttpClientService) {}
  serivceAPI = EndPoints.SERVICE_ENDPOINT;

  public createService(body) {
    return this.http.postFormData(body, `${this.serivceAPI}/create`);
  }

  public getAllServices(
    isPaginate: boolean = false,
    page?: number,
    active?: number,
    search?: string
  ) {
    if (!isPaginate) {
      page = null;
    }
    return this.http.get(`${this.serivceAPI}/all`, {
      is_paginate: `${+isPaginate}`,
      page,
      active,
      search,
    });
  }
  public getAllServicesLight(
    isPaginate: boolean = false,
    page?: number,
    active?: number,
    search?: string
  ) {
    if (!isPaginate) {
      page = null;
    }
    return this.http.get(`${this.serivceAPI}/index-light`, {
      is_paginate: `${+isPaginate}`,
      page,
      active,
      search,
    });
  }
  public getAllServicesbyModel(
    isPaginate: boolean = false,
    modelId?: number,
    page?: number,
    active?: number,
    search?: string
  ) {
    if (!isPaginate) {
      page = null;
    }
    return this.http.get(`${this.serivceAPI}/all`, {
      is_paginate: `${+isPaginate}`,
      model_id: modelId,
      page,
      active,
      search,
    });
  }
  public getAllServicesbyModelCustom(
    isPaginate: boolean = false,
    modelId?: number,
    page?: number,
    active?: number,
    search?: string
  ) {
    if (!isPaginate) {
      page = null;
    }
    return this.http.get(`${this.serivceAPI}/get-services`, {
      is_paginate: `${+isPaginate}`,
      model_id: modelId,
      page,
      active,
      search,
    });
  }
  public getAllServicesbycar(
    isPaginate: boolean = false,
    carId?: number,
    page?: number,
    active?: number,
    search?: string
  ) {
    if (!isPaginate) {
      page = null;
    }
    return this.http.get(`${this.serivceAPI}/all`, {
      is_paginate: `${+isPaginate}`,
      car_id: carId,
      page,
      active,
      search,
    });
  }
  public getAllServicesFilter(body) {
    return this.http.get(`${this.serivceAPI}/all`, body);
  }
  public getAllServicesFilterLight(body) {
    return this.http.get(`${this.serivceAPI}/index-light`, body);
  }
  public getAllServiceModelFilter(body) {
    return this.http.get(`${this.serivceAPI}/all-service-model`, body);
  }

  public getService(regionId: number, isLocalization: boolean = false) {
    return this.http.get(`${this.serivceAPI}/get`, {
      id: regionId,
      is_localization: `${+isLocalization}`,
    });
  }

  public updateService(body) {
    return this.http.postFormData(body, `${this.serivceAPI}/update`);
  }

  public deleteService(serviceId: number) {
    return this.http.post({ id: serviceId }, `${this.serivceAPI}/delete`);
  }
  public updateCareSparePart(form) {
    return this.http.post(form, `${this.serivceAPI}/update-spare-part`);
  }
  public updateModelService(form) {
    return this.http.post(form, `${this.serivceAPI}/get-model-service-spare`);
  }

  public getAllModel() {}
  public createModel(body) {
    return this.http.post(body, 'service/assign-model');
  }
  public createModelCustom(body) {
    return this.http.post(body, 'service/create-service');
  }
  public updateModel(body) {
    return this.http.post(body, 'service/edit-service-model');
  }
  public unAssignModel(body) {
    return this.http.post(body, 'service/unassign-model');
  }
  public getServiceModel(modelId: number) {
    return this.http.get('service/get-service-model', { id: modelId });
  }
  public getAllServiceModel(
    serviceId: number,
    isPaginate: boolean = false,
    page?: number,
    active?: number,
    search?: string
  ) {
    if (!isPaginate) {
      page = null;
    }
    return this.http.get(`${this.serivceAPI}/all-service-model`, {
      service_id: serviceId,
      is_paginate: `${+isPaginate}`,
      page,
      active,
      search,
    });
  }
  public downloadBulkData() {
    return this.http.post({}, `model/ExportServices`);
  }
  public uploadBulkData(file: File) {
    return this.http.postFormData({ file: file }, `model/importServices`);
  }
}


export interface ImodelModelOptions {
  page?: number;
  active?: number;
  is_paginate: 1 | 0;
  model_id?: number;
  manufacturer_id?: number;
  service_id?: number;
}


export interface ImodelServiceOptions {
  page?: number;
  active?: number;
  is_paginate: 1 | 0;

}
