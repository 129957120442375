import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';
import { CompaniesService } from '../../shared/services/companies.service';
import { ICompanyDetails } from '../../shared/models/icompany';
import { IPagination } from '../../../shared/models/response.model';

@Component({
  selector: 'app-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.scss']
})
export class ViewComponent implements OnInit {
  private id: number;
  public users: ICompanyDetails;
  public company;
  pagination: IPagination = {
    currentPage: 1,
    totalItems: 0,
    itemsPerPage: 12
  }

  constructor(private ActivatedRoute: ActivatedRoute, private companyService: CompaniesService) { }

  ngOnInit(): void {
    this.ActivatedRoute.paramMap.subscribe(param => {
      this.id = parseInt(param.get('id'))
      this.pagination.currentPage = parseInt(param.get('page'))
      this.getProfile();
      this.getUsers();

    })
  }
  getProfile() {
    this.companyService.getById(this.id).subscribe(res => {
      this.company = res.data?.user
      console.log(this.company)
    }, err => {
      console.log(err)
    })
  }
  getUsers() {
    this.companyService.getCompanyDetails(this.id, this.pagination.currentPage).subscribe(res => {
      this.pagination.totalItems = res.data?.total;
      this.pagination.itemsPerPage = res.data?.per_page;
      this.users = res?.data?.data;
    }, err => {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger mx-2'
        },
        buttonsStyling: false
      })
      swalWithBootstrapButtons.fire({
        title: 'Some thing went wrong!!',
        icon: 'error',
        showCancelButton: true,
        confirmButtonText: 'Retry',
        cancelButtonText: 'Cancel',
      }).then((result) => {
        if (result.isConfirmed) {
          this.getUsers();
        }
      })
    })
  }


}
