import { NgxSmartModalModule } from 'ngx-smart-modal';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CompaniesRoutingModule } from './companies-routing.module';
import { CompaniesComponent } from './components/companies/companies.component';
import { SharedModule } from '../shared/shared.module';
import { CreateComponent } from './components/create/create.component';
import { ViewComponent } from './components/view/view.component';
import { UpdateComponent } from './components/update/update.component';
import { OrdersComponent } from './components/orders/orders.component';
import { ViewPricesComponent } from './components/view-prices/view-prices.component';


@NgModule({
  declarations: [CompaniesComponent, CreateComponent, ViewComponent,  UpdateComponent, OrdersComponent, ViewPricesComponent],
  imports: [
    CommonModule,
    CompaniesRoutingModule,
    SharedModule,
    NgxSmartModalModule.forChild(),
  ],
  exports: [
    CreateComponent
  ]
})
export class CompaniesModule { }
