import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClientService } from 'src/app/shared/http-confing/http/http-client.service';
import { FacadeService } from 'src/app/shared/services/facade.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss'],
})
export class QrCreateComponent implements OnInit {
  urlLink;

  constructor(
    private http: HttpClientService,
    private router: Router,
    private facade: FacadeService
  ) {}

  ngOnInit(): void {}
  createQr() {
    console.log(this.urlLink);
    const payload = {
      link: this.urlLink,
    };
    this.http.post(payload, 'qr-codes/generate').subscribe({
      next: (res) => {
        console.log(res);
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: 'Something went wrong',
          showConfirmButton: false,
          timer: 1500,
        });
      },
      error: (err) => {
        console.log(err);

        Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'Qr is Generated successfully.',
          showConfirmButton: false,
          timer: 1500,
        }).then((result) => {
          this.router.navigate(['/qrCodes']);
        });
      },
    });
  }
}
