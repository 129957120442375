import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CompaniesService } from '../../shared/services/companies.service';
import { IOrder } from '../../shared/models/iorder';
import Swal from 'sweetalert2';
import { data } from 'jquery';

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss']
})
export class OrdersComponent implements OnInit {
  private id: number;
  public orders: IOrder[];
  constructor(private activatedRoute: ActivatedRoute, private companyService: CompaniesService) { }

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe(param => {
      this.id = parseInt(param.get('id'))
      this.getUserrOrders();
    })
  }
  getUserrOrders() {
    this.companyService.getOrders(this.id).subscribe(res => {
      console.log(res.data)
      if (res.data.length > 0) {
        this.orders = res.data
        console.log(this.orders[0])
      }
    }, err => {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger mx-2'
        },
        buttonsStyling: false
      })
      swalWithBootstrapButtons.fire({
        title: 'Some thing went wrong!!',
        icon: 'error',
        showCancelButton: true,
        confirmButtonText: 'Retry',
        cancelButtonText: 'Cancel',
      }).then((result) => {
        if (result.isConfirmed) {
          this.getUserrOrders();
        }
      })
    })
  }

}
