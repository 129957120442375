<div class="col-xl-12">
  <app-card [customHeader]="true" [options]="false">
    <div class="app-card-header">
      <h5 class="mb-3">
        <i class="fas fa-ticket-alt m-r-5"></i>
        {{ "Ref-Company" | translate }}
      </h5>
      <a class="btn btn-primary float-right align-items-center f-14" ngbTooltip="btn-primary"
        style="display: flex; margin: 5px" [routerLink]="['/ref-create']">
        <i class="fas fa-plus m-r-5"></i>{{ "buttons.create" | translate }}
      </a>
    </div>
    <div class="table-responsive" *ngIf="companies.length">
      <table class="table table-striped">
        <thead>
          <tr>

            <th>{{ "forms.#" | translate }}</th>
            <th>{{ "forms.name" | translate }}</th>
            <th>{{ "membership" | translate }}</th>
            <th>{{ "forms.Email" | translate }}</th>
            <th>{{ "Users Count" | translate }}</th>
            <th>{{ "Created at" | translate }}</th>

            <th>{{ "Wallet Offer" | translate }}</th>
            <th> code</th>


            <th class="text-center">{{ "forms.actions" | translate }}</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="
                let company of companies | paginate: pagination;
                let i = index
              ">
            <td>
              {{i+1}}
            </td>
            <td>
              <span class="text-secondary font-weight-bold">{{company?.name | slice : 0 : 15}}</span>
            </td>
            <td>
              <span class="text-secondary font-weight-bold">{{company?.membership?.name | slice : 0 : 15}}</span>

            </td>
            <td>
              <span class="text-secondary font-weight-bold">{{company?.email | slice : 0 : 15}}</span>

            </td>
            <td>
              <span class="text-secondary font-weight-bold" > {{company?.users_count}}
              </span>


            </td>
                <td>
              <span class="text-secondary font-weight-bold" > {{company?.membership?.created_at |date}}
              </span>


            </td>
            <td>
              <span class="text-secondary font-weight-bold" > {{company?.ref_wallet_offer}}
              </span>


            </td>
            <td>
              <span class="text-secondary font-weight-bold" > {{company?.code}}
              </span>


            </td>
            <td>
              <div class="overlay-edit text-center">
                <button type="button" class="btn btn-icon btn-outline-success"
                [routerLink]="['/ref-update/'+company.id]">
                <i class="fas fa-edit"></i>
              </button>
                <!--


                <button type="button" class="btn btn-icon btn-outline-danger mx-2" *ngIf="company?.active == 1"
                  (click)="confirmBan(company.id,0)" title="Ban">
                  <i class="fas fa-times fa-lg"></i>
                </button>
                <button type="button" class="btn btn-icon btn-outline-warning mx-2" *ngIf="company?.active == 0"
                  (click)="confirmBan(company.id,1)" title="Allow">
                  <i class="fas fa-check"></i>
                </button>
                <button type="button" class="btn btn-icon btn-outline-info"
                  [routerLink]="['/companies/view/' + company.id]" [queryParams]="{page:1}">
                  <i class="fas fa-eye"></i>
                </button>
                <button type="button" class="btn btn-icon btn-outline-primary mx-2"
                  [routerLink]="['/companies/view-discounts/' + company.id]" [queryParams]="{page:1}">
                  <i class="fas fa-dollar-sign"></i>
                </button> -->
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <app-alert *ngIf="!companies.length" type="danger  text-center"> {{'errors.nofound' |translate}} {{
      "navigation.company_list" | translate }}</app-alert>

    <div class="row">
      <div class="col-12 text-center">
        <pagination-controls *ngIf="
        companies.length && pagination.totalItems > pagination.itemsPerPage
        " (pageChange)="pageChanged($event)" [nextLabel]="('buttons.next' | translate)"
          [previousLabel]="('buttons.previous' | translate)" screenReaderPaginationLabel="Pagination"
          screenReaderPageLabel="page" screenReaderCurrentLabel="You're on page">
        </pagination-controls>
      </div>
    </div>
  </app-card>
</div>
