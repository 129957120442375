import { Component, OnInit } from '@angular/core';
import { HttpClientService } from '../shared/http-confing/http/http-client.service';
import { IPagination } from '../shared/models/response.model';

@Component({
  selector: 'app-qr-codes',
  templateUrl: './qr-codes.component.html',
  styleUrls: ['./qr-codes.component.scss'],
})
export class QrCodesComponent implements OnInit {
  qrList: { [key: string]: any }[] = [];

  paginationOptions: IPagination = {
    currentPage: 1,
    totalItems: 0,
    itemsPerPage: 15,
  };
  constructor(private http: HttpClientService) {}
  ngOnInit(): void {
    this.http.get('qr-codes/all').subscribe((res: any) => {
      this.paginationOptions.itemsPerPage = res.meta.per_page;
      this.paginationOptions.totalItems = res.meta.total;
      this.qrList = res.data;
    });
  }
  pageChanged($event: number) {
    this.paginationOptions.currentPage = $event;
    this.http
      .get('qr-codes/all', {
        page: $event,
      })
      .subscribe((res: any) => {
        this.paginationOptions.itemsPerPage = res.meta.per_page;
        this.paginationOptions.totalItems = res.meta.total;
        this.qrList = res.data;
        console.log(res);
      });
  }
  onImgError(event) {
    event.target.src = 'assets/images/user/avatar-1.jpg';
  }
}
