import { ActivateUserService } from './../../auth/shared/services/activate-user.service';
import { PrivacyAndTermsService } from './privacy-and-term/privacy-and-terms.service';
import { SendnotificationService } from './sendnotification/sendnotification.service';
import { PaymentService } from './payment/payment.service';
import { ReportsService } from './reports/reports.service';
import { MembershipService } from './membership/membership.service';
import { ComplaintService } from './complaint/compliant.service';
import { SlotService } from './../../orders/shared/services/slot.service';
import { SparePartsService } from './../../services/shared/services/spareparts.service';
import { SearchService } from './search/search.service';
import { AuthBanService } from './../../auth/shared/services/ban.service';
import { CustomerService } from './../../user/shared/services/customer.service';
import { ModelYearService } from './../../cars/model-year/shared/services/model-year.service';
import { ModelService } from './../../cars/model/shared/services/model.service';
import { Injectable, Injector } from '@angular/core';
import { NotifierService } from '../helpers/notifier.service';
import { ManufacturerService } from './../../cars/manufacturer/shared/services/manufacturer.service';
import { RegionService } from '../services/region/region.service';
import { AreasService } from '../services/areas/areas.service';
import { CountryService } from '../services/country/country.service';
import { ServicesService } from '../services/services/services.service';
import { OperationService } from '../services/operation/operation.service';
import { TechnicianService } from '../services/technician/technician.service';
import { CouponService } from '../services/orders/coupon/coupon.service';
import { OrdersService } from '../services/orders/order/orders.service';
import { MessagingService } from './notification/notification.service';
import { NotificationsService } from './notifications/notifications.service';
import { CategoriesService } from '../../services/shared/services/categories.service';
import { CarSparePartsService } from '../../services/shared/services/carSpareParts.service';

@Injectable({
  providedIn: 'root',
})
export class FacadeService {
  private _notifyService: NotifierService;
  private _manufacturerService: ManufacturerService;
  private _modelService: ModelService;
  private _modelYearService: ModelYearService;
  private _customerService: CustomerService;
  private _searchService: SearchService;

  private _regionService: RegionService;
  private _areasService: AreasService;
  private _countryService: CountryService;
  private _serviceService: ServicesService;
  private _operationService: OperationService;
  private _technicianService: TechnicianService;
  private _authBanService: AuthBanService;
  private _activateService: ActivateUserService
  private _coupongService: CouponService;
  private _sparePartsService: SparePartsService;
  private _categoriesService: CategoriesService;
  private _slotService: SlotService;
  private _messageService: MessagingService;
  private _notificationService: NotificationsService;
  private _orderService: OrdersService;
  private _complaintService: ComplaintService;
  private _membershipService: MembershipService;
  private _reportsService: ReportsService;
  private _paymentService: PaymentService;
  private _sendNotificationService: SendnotificationService;
  private _termsAndPrivacyService: PrivacyAndTermsService;
  private _carSparePartsService: CarSparePartsService;


  constructor(private injector: Injector) {}
  get complaintService(): ComplaintService {
    if (!this._complaintService) {
      this._complaintService = this.injector.get(ComplaintService);
    }

    return this._complaintService;
  }
  get searchService(): SearchService {
    if (!this._searchService) {
      this._searchService = this.injector.get(SearchService);
    }

    return this._searchService;
  }
  get notifyService(): NotifierService {
    if (!this._notifyService) {
      this._notifyService = this.injector.get(NotifierService);
    }

    return this._notifyService;
  }
  get modelService(): ModelService {
    if (!this._modelService) {
      this._modelService = this.injector.get(ModelService);
    }

    return this._modelService;
  }
  get modelYearService(): ModelYearService {
    if (!this._modelYearService) {
      this._modelYearService = this.injector.get(ModelYearService);
    }

    return this._modelYearService;
  }
  get manufacturerService(): ManufacturerService {
    if (!this._manufacturerService) {
      this._manufacturerService = this.injector.get(ManufacturerService);
    }

    return this._manufacturerService;
  }
  get customerService(): CustomerService {
    if (!this._customerService) {
      this._customerService = this.injector.get(CustomerService);
    }

    return this._customerService;
  }
  get regionService(): RegionService {
    if (!this._regionService) {
      this._regionService = this.injector.get(RegionService);
    }

    return this._regionService;
  }
  get areasService(): AreasService {
    if (!this._areasService) {
      this._areasService = this.injector.get(AreasService);
    }

    return this._areasService;
  }
  get countryService(): CountryService {
    if (!this._countryService) {
      this._countryService = this.injector.get(CountryService);
    }

    return this._countryService;
  }
  get serviceServices(): ServicesService {
    if (!this._serviceService) {
      this._serviceService = this.injector.get(ServicesService);
    }

    return this._serviceService;
  }
  get operationService(): OperationService {
    if (!this._operationService) {
      this._operationService = this.injector.get(OperationService);
    }

    return this._operationService;
  }
  get techicianService(): TechnicianService {
    if (!this._technicianService) {
      this._technicianService = this.injector.get(TechnicianService);
    }

    return this._technicianService;
  }
  get authBanService(): AuthBanService {
    if (!this._authBanService) {
      this._authBanService = this.injector.get(AuthBanService);
    }

    return this._authBanService;
  }
  get activateUserService(): ActivateUserService {
    if (!this._activateService) {
      this._activateService = this.injector.get(ActivateUserService);
    }

    return this._activateService;
  }

  get couponService(): CouponService {
    if (!this._coupongService) {
      this._coupongService = this.injector.get(CouponService);
    }

    return this._coupongService;
  }
  get sparePartsService(): SparePartsService {
    if (!this._sparePartsService) {
      this._sparePartsService = this.injector.get(SparePartsService);
    }

    return this._sparePartsService;
  }
  get carSparePartsService(): CarSparePartsService {
    if (!this._carSparePartsService) {
      this._carSparePartsService = this.injector.get(CarSparePartsService);
    }

    return this._carSparePartsService;
  }
  get categoriesService(): CategoriesService {
    if (!this._categoriesService) {
      this._categoriesService = this.injector.get(CategoriesService);
    }

    return this._categoriesService;
  }
  get slotService(): SlotService {
    if (!this._slotService) {
      this._slotService = this.injector.get(SlotService);
    }

    return this._slotService;
  }
  public get messageService(): MessagingService {
    if (!this._messageService) {
      this._messageService = this.injector.get(MessagingService);
    }
    return this._messageService;
  }
  public get notificationService(): NotificationsService {
    if (!this._notificationService) {
      this._notificationService = this.injector.get(NotificationsService);
    }
    return this._notificationService;
  }
  public get orderService(): OrdersService {
    if (!this._orderService) {
      this._orderService = this.injector.get(OrdersService);
    }
    return this._orderService;
  }
  public get memberShipService(): MembershipService {
    if (!this._membershipService) {
      this._membershipService = this.injector.get(MembershipService);
    }
    return this._membershipService;
  }
  public get reportsService(): ReportsService {
    if (!this._reportsService) {
      this._reportsService = this.injector.get(ReportsService);
    }
    return this._reportsService;
  }
  public get paymentService(): PaymentService {
    if (!this._paymentService) {
      this._paymentService = this.injector.get(PaymentService);
    }
    return this._paymentService;
  }
  public get sendNotificationSerivce(): SendnotificationService {
    if (!this._sendNotificationService) {
      this._sendNotificationService = this.injector.get(SendnotificationService);
    }
    return this._sendNotificationService;
  }
  public get termsAndPrivacyService(): PrivacyAndTermsService {
    if (!this._termsAndPrivacyService) {
      this._termsAndPrivacyService = this.injector.get(PrivacyAndTermsService);
    }
    return this._termsAndPrivacyService;
  }
}
