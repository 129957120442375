import { HttpClientService } from '../../../../shared/http-confing/http/http-client.service';
import { EndPoints } from '../../../../shared/endpoints/endpoints';
import { Response } from '../../../../shared/models/response.model';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { ModelYear } from '../models';

@Injectable({
  providedIn: 'root',
})
export class ModelYearService {
  apiURLModelYear = EndPoints.MODEL_YEAR_ENDPOINT;

  constructor(private https: HttpClientService, private router: Router) {}
  // lang?: string
  getAll(
    isPaginate: boolean = false,
    page?: number,
    active?: number,
    search?: string,
    payload?: any
  ): Observable<any> {
    if (!isPaginate) {
      page = null;
    }
    return this.https.get(`${this.apiURLModelYear}/all`, {
      is_paginate: `${+isPaginate}`,
      page,
      active,
      search,
      payload
    });
  }
  getAllFilter(body) {
    return this.https.get(`${this.apiURLModelYear}/all`, body);
  }

  getById(Id): Observable<Response<ModelYear>> {
    return this.https.get(`${this.apiURLModelYear}/get`, { id: Id });
  }

  create(payLoad): Observable<Response<ModelYear>> {
    return this.https.postFormData(payLoad, `${this.apiURLModelYear}/create`);
  }

  update(payLoad): Observable<Response<ModelYear>> {
    return this.https.postFormData(payLoad, `${this.apiURLModelYear}/update`);
  }
  delete(id): Observable<Response<ModelYear>> {
    return this.https.post({ id: id }, `${this.apiURLModelYear}/delete`);
  }
  getFilterYear(payload): Observable<any> {

    return this.https.get(`${this.apiURLModelYear}/all`, payload);
  }
}
